ObligationToMakeRoutes.$inject = ['$routeProvider', 'ObligationToMakeConstants'];

export default function ObligationToMakeRoutes($routeProvider, ObligationToMakeConstants) {
  $routeProvider.when('/obligationtomake/lawyers/counts', {
    title: 'Panel Abogados Demandas de Obligación de Hacer',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-OH-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Demandas de Obligación de Hacer',
      iconClass: 'fas fa-briefcase',
      cardHead: 'Panel Abogados Demandas de Obligación de Hacer'
    },
    controller: 'ObligationToMakeLawyerPanelCtrl',
    controllerAs: 'vm',
    segmentID: ObligationToMakeConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-OH-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const claimListConfig = {
    title: 'Listado reclamaciones de Demandas de Obligación de Hacer',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Demandas de Obligación de Hacer',
      iconClass: 'fas fa-briefcase'
    },
    baseListSettings: {
      typeList: 'obligationtomake',
      typeTitle: 'obligationtomake',
      templateListTable: 'js/modules/segments/obligationtomake/partials/claim-list-table.html',
      templateListFilter: 'js/modules/segments/obligationtomake/partials/claim-list-filters.html',
      title: 'Reclamaciones Demandas de Obligación de Hacer',
      listResultsText: 'reclamaciones demandas de obligación de hacer'
    },
    controller: 'ObligationToMakeClaimListCtrl',
    controllerAs: 'vm',
    segmentID: ObligationToMakeConstants.SEGMENT_ID
  };

  $routeProvider.when('/obligationtomake-claims', claimListConfig);
  $routeProvider.when('/obligationtomake-claims/list/filter/:filter', claimListConfig);

  $routeProvider.when('/obligationtomake-claims/:id', {
    title: 'Detalle reclamación de Demandas de Obligación de Hacer',
    templateUrl: 'js/modules/segments/obligationtomake/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-OH-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Demandas de Obligación de Hacer',
      iconClass: 'fas fa-briefcase'
    },
    controller: 'ObligationToMakeClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: ObligationToMakeConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
