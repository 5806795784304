import angular from 'angular';

import ObligationToMakeClaimListCtrl from './claimListCtrl';
import ObligationToMakeClaimDetailCtrl from './claimDetailCtrl';

// Lists
import ObligationToMakeLawyerPanelCtrl from './lawyerPanelCtrl';

import ObligationToMakeRoutes from './routes';
import ObligationToMakeConstants from './constants';
import ObligationToMakeClaim from './services/claimService';
import ObligationToMakeLawyer from './services/lawyerService';

angular
  .module('reclamador.segments.obligationtomake', [])
  .controller('ObligationToMakeClaimListCtrl', ObligationToMakeClaimListCtrl)
  .controller('ObligationToMakeClaimDetailCtrl', ObligationToMakeClaimDetailCtrl)
  .controller('ObligationToMakeLawyerPanelCtrl', ObligationToMakeLawyerPanelCtrl)

  .factory('ObligationToMakeClaim', ObligationToMakeClaim)
  .factory('ObligationToMakeLawyer', ObligationToMakeLawyer)

  .constant('ObligationToMakeConstants', ObligationToMakeConstants)
  .config(ObligationToMakeRoutes);
