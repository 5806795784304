import angular from 'angular';

import './legalfiles';
import './airline';
import './bank';
import './obligationtomake';
import './taxes';
import './laboral';
import './accident';
import './negligences';
import './so';
import './phone';
import './generic';
import './legalservices';

import './foreignerservices';
import './management';

import tabs from 'ui-bootstrap4/src/tabs';

angular.module('reclamador.segments', [
  tabs,

  'reclamador.controllers.base',

  'reclamador.segments.legalfiles',

  'reclamador.segments.airline',
  'reclamador.segments.bank',
  'reclamador.segments.obligationtomake',
  'reclamador.segments.taxes',
  'reclamador.segments.laboral',
  'reclamador.segments.accident',
  'reclamador.segments.negligences',
  'reclamador.segments.so',
  'reclamador.segments.phone',
  'reclamador.segments.generic',

  'reclamador.segments.foreignerServices',
  'reclamador.segments.managements',
  'reclamador.segments.legalservices'
]);
