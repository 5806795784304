const SEGMENT_ID = 12;
const MODEL_PROVIDER = 'ObligationToMakeClaim';

const CONFIG = {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'OH',
  ALIAS: 'obligationtomake',
  VERBOSE_NAME: 'Reclamación de demandas de obligación de hacer',
  permissions: {
    downloadCSVPermission: 'segment-perobject-OH-crm_download_csv'
  },
  CLAIM_DICTIONARIES: {
    users: 'obligationtomakelawyers',
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    provinces: 'provinces',
    claimTypes: {
      entity: 'claimtypes',
      data: { type: 'obligationtomake' }
    },
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: MODEL_PROVIDER }
    },
    docxtemplates: {
      entity: 'docxtemplates',
      data: { active: true, segments__id: SEGMENT_ID, target_holder: 'claim' }
    }
  },
  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/obligationtomake/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/obligationtomake/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/obligationtomake/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: '/partials/common/detail/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  // TODO: Hacer funcionar los filtros y columnas
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true },
    { headerName: 'Origen', fieldName: 'original_claim_full_id', sortable: true },
    { headerName: 'Cliente', fieldName: 'client_surnames', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true, hidden: true },
    { headerName: 'Provincia cliente', fieldName: 'client_province', sortable: true, hidden: true },
    { headerName: 'Tlfno', fieldName: 'client_phone', sortable: true, hidden: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    { headerName: 'Demandado', fieldName: 'company_sued', sortable: true, alwaysVisible: true }
  ],
  filterFieldInfo: {
    company_sued: { label: 'Demandado', json_value: 'true', use_field: 'name' }
  }

};

export default CONFIG;
