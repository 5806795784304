import angular from 'angular';
import ProductRevolvingCtrl from './ProductRevolvingCtrl';

angular
  .module('reclamador.components.claim.info.manage.productRevolving', [])
  .component('productRevolving', {
    template: require('./product-revolving.html'),
    controller: ProductRevolvingCtrl,
    controllerAs: 'vm',
    bindings: {
      revolvingClaim: '<',
      isEditable: '<'
    }
  });
