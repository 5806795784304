DocumentDownloadFactory.$inject = ['$http', '$window', 'serverAddress', 'notifications'];

export default function DocumentDownloadFactory($http, $window, serverAddress, notifications) {
  var service = {
    downloadDocumentDirectly,
    downloadDocument,
    downloadAllAirlineDocuments,
    downloadAllTaxesDocuments,
    downloadAllLaboralDocuments,
    downloadAllNegligencesDocuments,
    downloadAllAccidentsDocuments,
    downloadAllPhoneDocuments,
    downloadAllObligationToMakeDocuments,
    downloadAllBankDocuments
  };

  return service;

  function downloadDocumentDirectly(document) {
    if (document.url) {
      $window.open(document.url);
    } else {
      notifications.addCurrentView(
        'error',
        'El documento con ID ' + document.id + ' no se ha subido o no se puede descargar.'
      );
    }
  }

  function downloadDocument(documentType, documentId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'common/download-document/',
      method: 'POST',
      dataType: 'json',
      data: {
        doc_id: documentId,
        doc_type: documentType
      }
    });
  }

  function downloadAllAirlineDocuments(claim) {
    return downloadAllSegmentDocuments('claim/', claim);
  }

  function downloadAllTaxesDocuments(claim) {
    return downloadAllSegmentDocuments('taxes-claim/', claim);
  }

  function downloadAllLaboralDocuments(claim) {
    return downloadAllSegmentDocuments('laboral-claim/', claim);
  }

  function downloadAllNegligencesDocuments(claim) {
    return downloadAllSegmentDocuments('negligences-claim/', claim);
  }

  function downloadAllAccidentsDocuments(claim) {
    return downloadAllSegmentDocuments('accident-claim/', claim);
  }

  function downloadAllPhoneDocuments(claim) {
    return downloadAllSegmentDocuments('phone-claim/', claim);
  }

  function downloadAllObligationToMakeDocuments(claim) {
    return downloadAllSegmentDocuments('obligationtomake-claim/', claim);
  }

  function downloadAllBankDocuments(claim) {
    $http({
      url: serverAddress.getBaseUrl() + 'bank-claim/claim/download-all-documents/',
      method: 'POST',
      data: {
        id: claim.id
      }
    }).then(function(response) {
      if (response.data.error) {
        notifications.addCurrentView('error', response.data.msg);
      } else {
        notifications.addCurrentView('success', response.data.msg);
      }
    });
  }

  /* Private functions */
  function downloadAllSegmentDocuments(path, claim) {
    $http({
      url: serverAddress.getBaseUrl() + path + claim.id + '/download-all-documents/',
      method: 'GET'
    }).then(function(response) {
      if (response.data.error) {
        notifications.addCurrentView('error', response.data.msg);
      } else {
        notifications.addCurrentView('success', response.data.msg);
      }
    });
  }
}
