NewCommentCtrl.$inject = ['$scope', 'serverAddress', 'notifications', '$http'];

export default function NewCommentCtrl($scope, serverAddress, notifications, $http) {
  const vm = this;

  const ADD_COMMENT_PATH = 'common/claim/comment/add/';
  const ADD_LEGALFILE_COMMENT_PATH = 'legalfile/comment/add/';
  const ADD_CLIENT_COMMENT_PATH = 'client/comment/add/';

  const INITIALSTATE = {
    displayNewComment: false, // toggle form display, by defaul hidden

    // form data
    comment: null,
    comments_is_important: null,
    comments_is_crossed_comment: null,
    comments_show_in_docs_tab: null,
    is_crossed_comment_original_claim: null,
    is_crossed_comment_obligation_to_make: null,

    pendingSubmitComment: false,

    // public methods
    postNewComment,
    resetForm
  };
  Object.assign(vm, INITIALSTATE);

  function postNewComment() {

    if (this.pendingSubmitComment) {
      return;
    }

    let req = null;
    switch (vm.objectType) {
      case 'client':
        req = _postClientNewComment();
        break;
      case 'legalfile':
        req = _postLegalfileNewComment();
        break;
      default:
        // asume claim
        req = _postClaimNewComment();
        break;
    }

    this.pendingSubmitComment = true;

    req
      .then(function() {
        resetForm();
        if (vm.sucessCb) vm.sucessCb();
      })
      .catch(() => {
        notifications.addCurrentView('error', 'Se produjo un error enviando el comentario');
      });
  }

  function resetForm() {
    vm.comment = '';
    vm.comments_is_important = false;
    vm.comments_is_crossed_comment = false;
    vm.comments_show_in_docs_tab = false;
    vm.is_crossed_comment_original_claim = false;
    vm.is_crossed_comment_obligation_to_make = false;

    vm.displayNewComment = false;

    vm.pendingSubmitComment = false;
    vm.form.$setPristine();
  }

  function _postClaimNewComment() {
    return $http.post(serverAddress.getBaseUrl() + ADD_COMMENT_PATH, {
      claim_id: vm.objectId,
      comment: vm.comment,
      django_class: vm.objectType,
      is_important: !!vm.comments_is_important,
      is_crossed_comment: !!vm.comments_is_crossed_comment,
      show_in_docs_tab: !!vm.comments_show_in_docs_tab,
      is_crossed_comment_original_claim: !!vm.is_crossed_comment_original_claim,
      is_crossed_comment_obligation_to_make: !!vm.is_crossed_comment_obligation_to_make
    });
  }

  function _postLegalfileNewComment() {
    return $http.post(serverAddress.getBaseUrl() + ADD_LEGALFILE_COMMENT_PATH, {
      legalfile_id: vm.objectId,
      comment: vm.comment,
      is_important: !!vm.comments_is_important,
      is_crossed_comment: !!vm.comments_is_crossed_comment,
      show_in_docs_tab: !!vm.comments_show_in_docs_tab
    });
  }

  function _postClientNewComment() {
    return $http.post(serverAddress.getBaseUrl() + ADD_CLIENT_COMMENT_PATH, {
      client_id: vm.objectId,
      comment: vm.comment
    });
  }
}
