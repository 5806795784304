ClaimFactory.$inject = ['$http', 'serverAddress', '$window', '$localStorage'];

export default function ClaimFactory($http, serverAddress, $window, $localStorage) {
  return {
    finishClaim,
    loadClientClaims,
    isValidId
  };

  function finishClaim(claimId, segmentPrefix) {
    const FinishClaimEndPoints = {
      AC: 'common/claim/accident/finish/',
      AE: 'common/claim/airline/finish/',
      BA: 'common/claim/bank/finish/',
      OH: 'common/claim/obligationtomake/finish/',
      EX: 'common/claim/foreignerservices/finish/',
      LA: 'common/claim/laboral/finish/',
      NE: 'common/claim/negligencesclaim/finish/',
      TE: 'common/claim/phone/finish/',
      SO: 'common/claim/so/finish/',
      TA: 'common/claim/municipaltaxclaim/finish/',
      GE: 'common/claim/managements/finish/',
      SG: 'common/claim/legalservices/finish/'
    };

    if(!FinishClaimEndPoints[segmentPrefix]) {
      throw new Error(`FinishClaim called with unknwown ${segmentPrefix} segment prefix.`);
    }

    return $http.post(serverAddress.getBaseUrl() + FinishClaimEndPoints[segmentPrefix], {
      claim_id: claimId,
      username: $localStorage.get('username')
    });
  }

  function loadClientClaims(claim, prefix) {
    return $http({
      url: serverAddress.getBaseUrl() + 'common/claim/' + prefix + '/' + claim + '/all-user-claims/',
      method: 'GET',
      dataType: 'json'
    });
  }

  // Basic claim and legalfile id validator
  function isValidId(id) {
    if(!id || typeof id !== 'string') return false;

    const FIXED_ID = id.replace(/[^\x00-\x7F]/g, '').toUpperCase();

    if(FIXED_ID.length < 3 ||
      (/[^0-9\/]/g).exec(FIXED_ID.substr(2)) ||
      (/[^A-Za-z]/g).exec(FIXED_ID.substr(0, 2))) return false;
    return FIXED_ID;
  };
}
