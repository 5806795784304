ObligationToMakeLawyerPanelCtrl.$inject = ['$scope', '$controller', 'ObligationToMakeConstants'];

export default function ObligationToMakeLawyerPanelCtrl($scope, $controller, ObligationToMakeConstants) {
  const vm = this;
  vm.baseLink = '#/obligationtomake-claims/list/filter';
  vm.segmentID = ObligationToMakeConstants.SEGMENT_ID;
  $controller('BaseLawyerPanelCtrl', { vm, $scope });

  vm.init(ObligationToMakeConstants.SEGMENT_ID, 'obligationtomakelawyers');
}
