import { copy } from 'angular';

CopyDocumentsCtrl.$inject = [
  'currentDocuments',
  'documentTypes',
  'claimTypes',
  'claimData',
  'multipleSourceSelectionEnabled',
  'dialog',
  'DocumentCopyFactory',
  'ClaimFactory',
  'notifications'
];

export default function CopyDocumentsCtrl(
  currentDocuments,
  documentTypes,
  claimTypes,
  claimData,
  multipleSourceSelectionEnabled,
  dialog,
  DocumentCopyFactory,
  ClaimFactory,
  notifications
) {
  var vm = this;

  /* Scope functions */
  vm.closeModal = closeModal;
  vm.copyDocumentsToClaim = copyDocumentsToClaim;
  vm.copyClaimDocumentsToLegalfile = copyClaimDocumentsToLegalfile;
  vm.copyClaimDocumentsFromLegalfile = copyClaimDocumentsFromLegalfile;
  vm.resetPanels = resetPanels;
  vm.checkDocPermission = checkDocPermission;
  vm.loadUserAllClaims = loadUserAllClaims;
  vm.copyDocumentsNewClaim = copyDocumentsNewClaim;
  vm.getSegmentPath = getSegmentPath;

  /* Scope data */
  vm.documentTypes = [];
  vm.claimTypes = [];
  vm.currentDocuments = [];
  vm.selectedDocuments = [];
  vm.multipleSourceSelectionEnabled = multipleSourceSelectionEnabled;

  vm.selectedDocument = null;
  vm.selectedDocumentType = null;
  vm.selectedClaimType = null;
  vm.lawyerComment = null;
  vm.displayOverlay = true;
  vm.targetClaim = null;
  vm.copyDocumentsOption = null;
  vm.copyDisabled = true;
  vm.clientName = null;
  vm.allClaimsByType = [];
  vm.listPathNoFIlter = null;

  /* Init */
  init();

  /* Functions */
  function init() {
    vm.targetClaim = claimData['targetClaim'];
    vm.documentTypes = copy(documentTypes);
    vm.claimTypes = copy(claimTypes);

    if(!vm.targetClaim) {
      ClaimFactory.loadClientClaims(claimData['claim_id'], claimData['claim_prefix']).then(function(response) {
        vm.allClaimsByType = response.data['claims'];
        vm.clientName = response.data['client'];
      });
    }

    resetPanels();
  }

  function _validateParams() {
    // Reset error messages list
    var hasErrors = false;
    vm.copyDisabled = true;

    // Filter the selected documents
    vm.selectedDocuments = vm.currentDocuments.filter(d => d.selected === true);

    if (vm.selectedDocuments.length === 0) {
      notifications.addCurrentView('error', 'Por favor, selecciona al menos un documento.');
      hasErrors = true;
    }

    // If "Copy to existing claim" is selected, it must have specified a claim ID to copy the documents
    if (vm.copyDocumentsOption === 'existing_claim' && (!vm.targetClaim || vm.targetClaim === '')) {
      notifications.addCurrentView(
        'error',
        'Por favor, indica la reclamación en la que quieres copiar los documentos.'
      );
      hasErrors = true;
    }

    return !hasErrors;
  }

  function checkDocPermission() {
    notifications.clear();
    var showWarning = false;

    // Filter the selected documents
    vm.selectedDocuments = vm.currentDocuments.filter(d => d.selected === true);

    // When the target claim ID exists, check if it belongs to the same segment. If not, we show a warning message for
    // the selected documents with copy permission == 2
    if (vm.copyDocumentsOption === 'existing_claim' && vm.targetClaim) {
      if (!checkTargetIdFormat(vm.targetClaim)) {
        notifications.addCurrentView(
          'error',
          'El formato del ID de reclamación es incorrecto. El ID debe incluir las ' +
            'letras de identificación del vertical. Por ejemplo, NE000.'
        );
        return;
      }

      var source_claim = claimData['claim_prefix'] + claimData['claim_id'];
      // If the target claim is the same as the current claim, an error is shown
      if (vm.targetClaim.toUpperCase() === source_claim.toUpperCase()) {
        notifications.addCurrentView(
          'error',
          'No se ha podido realizar la acción. El ID introducido es el de la ' + 'reclamación en uso.'
        );
        return;
      }

      var claimPrefix = vm.targetClaim.slice(0, 2);
      if (claimPrefix.toUpperCase() === claimData['claim_prefix'].toUpperCase()) {
        // Removed warning color from selection
        vm.selectedDocuments.forEach(doc => {
          doc.warning = false;
        });
      } else {
        // Each doc with allow_copy_level == 2 must changed the background color
        vm.selectedDocuments.forEach(doc => {
          if (doc.type.allowed_copy_level === 2) {
            doc.warning = true;
            showWarning = true;
          }
        });

        // If there was at least one doc with warning permission, the warning will be shown
        if (showWarning) {
          notifications.addCurrentView(
            'warning',
            'Los documentos señalados en el listado no son propios de una ' +
              'reclamación del tipo ' +
              getSegmentText(vm.targetClaim.slice(0, 2).toUpperCase()) +
              '. ¿Quieres copiarlos de todos modos?'
          );
        }
      }
    }

    vm.copyDisabled = !(vm.selectedDocuments.length !== 0 && vm.targetClaim);
  }

  function copyDocumentsToClaim(validateDocumentsOption = true) {
    if (!_validateParams()) return;

    if (validateDocumentsOption && vm.copyDocumentsOption === null) {
      notifications.addCurrentView('error', 'Por favor, indica qué acción quieres realizar con los documentos.');
    }

    // Get the document ids
    var documentsIdsToCopy = vm.selectedDocuments.map(idsToCopy => idsToCopy.id);

    claimData['new_claim_id'] = vm.targetClaim;
    DocumentCopyFactory.copyDocumentsToAnotherClaim(claimData, documentsIdsToCopy)
      .then(function() {
        dismiss();
        notifications.addCurrentView(
          'success',
          'Estamos copiando los documentos a la reclamación ' + vm.targetClaim + '. Recibirás un email una vez termine.'
        );
      })
      .catch(function(e) {
        if (e.status === 404) {
          notifications.addCurrentView('error', 'No se encuentra la reclamación de destino con id ' + vm.targetClaim);
        }
        if (e.status === 403) {
          notifications.addCurrentView(
            'error',
            'La reclamación de destino está asociada a otro cliente. ' +
              'Recuerda que solo está permitido copiar documentos entre reclamaciones del mismo cliente.'
          );
        }
      });
  }

  function copyDocumentsNewClaim() {
    if (!_validateParams()) return;

    if (vm.copyDocumentsOption === null) {
      notifications.addCurrentView('error', 'Por favor, indica qué acción quieres realizar con los documentos.');
    }

    // Get the document ids
    var documentsIdsToCopy = vm.selectedDocuments.map(idsToCopy => idsToCopy.id);

    claimData['new_claim_type_id'] = vm.selectedClaimType.id;
    claimData['lawyer_comment'] = vm.lawyerComment;

    DocumentCopyFactory.copyDocumentsToNewClaim(claimData, documentsIdsToCopy)
      .then(function(response) {
        dismiss();
        notifications.addCurrentView('success', response.data);
      })
      .catch(function() {
        notifications.addCurrentView('error', 'No se ha podido realizar la acción.');
      });
  }

  function copyClaimDocumentsToLegalfile() {
    if (!_validateParams()) return;

    // Get the document ids
    var documentsIdsToCopy = vm.selectedDocuments.map(idsToCopy => idsToCopy.id);

    DocumentCopyFactory.copyDocumentsToLegalfile(claimData, documentsIdsToCopy)
      .then(function(response) {
        dismiss();
        notifications.addCurrentView('success', response.data);
      })
      .catch(function() {
        notifications.addCurrentView('error', 'No se ha podido realizar la acción.');
      });
  }

  function copyClaimDocumentsFromLegalfile() {
    if (!_validateParams()) return;

    // Get the document ids
    var documentsIdsToCopy = vm.selectedDocuments.map(idsToCopy => idsToCopy.id);

    DocumentCopyFactory.copyDocumentsFromLegalfile(claimData, documentsIdsToCopy)
      .then(function(response) {
        dismiss();
        notifications.addCurrentView('success', response.data);
      })
      .catch(function() {
        notifications.addCurrentView('error', 'No se ha podido realizar la acción.');
      });
  }

  function checkTargetIdFormat(targetId) {
    return targetId.match(/^[A-Z]{2}/i);
  }

  function closeModal() {
    dialog.close(false); // Do not run callback
  }

  function dismiss() {
    dialog.close(true); // Run callback
  }

  function resetPanels() {
    vm.currentDocuments = copy(currentDocuments);
  }

  function getSegmentText(prefix) {
    // This is shit but is there another way to show the segment as text, when a target claim is typed?
    if (prefix === 'AE') {
      return 'aerolíneas';
    } else if (prefix === 'BA') {
      return 'banca';
    } else if (prefix === 'LA') {
      return 'laboral';
    } else if (prefix === 'TA') {
      return 'impuestos';
    } else if (prefix === 'NE') {
      return 'negligencias';
    } else if (prefix === 'AC') {
      return 'accidentes';
    } else if (prefix === 'TE') {
      return 'telefonía';
    } else if (prefix === 'SO') {
      return 'segunda oportunidad';
    }
    return '';
  }

  function getSegmentPath(prefix) {
    const segmentPaths = {
      AE: '/airline-claims/',
      BA: '/bank-claims/',
      LA: '/laboral-claims/',
      TA: '/taxes-claims/',
      NE: '/negligences-claims/',
      AC: '/accident-claims/',
      TE: '/phone-claims/',
      SO: '/so-claims/'
    };
    return segmentPaths[prefix] ? segmentPaths[prefix] : '';
  }

  function loadUserAllClaims() {
    vm.claimsFilter = vm.allClaimsByType.filter(d => d.type === vm.selectedClaimType.id);
    var claim_prefix = claimData['claim_prefix'];
    vm.claimsFilter.forEach(claim => {
      claim['full_id'] = claim_prefix + claim.id;
      claim['full_id_link'] = '#' + vm.getSegmentPath(claim_prefix) + claim.id;
    });

    return vm.claimsFilter;
  }
}
