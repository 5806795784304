import angular from 'angular';
import './product-revolving';
import ClaimDetailProductManage from './ClaimDetailProductManageCtrl';

angular
  .module('reclamador.components.claim.info.claimDetailProductManage', [
    'reclamador.components.claim.info.manage.productRevolving'
  ])
  .component('claimDetailProductManage', {
    template: require('./claim-detail-product-manage.html'),
    controller: ClaimDetailProductManage,
    controllerAs: 'vm',
    bindings: {
      claimTypeId: '<',
      claim: '<',
      isEditable: '<'
    }
  });
