ProductRevolvingCtrl.$inject = [];

export default function ProductRevolvingCtrl() {
  const vm = this;

  vm.creditCardType = null;
  vm.isEditable = null;
  vm.details = [];

  vm.$onInit = () => {
    loadDetails();
  };

  function loadDetails(){
    vm.details = [
      {
        id: 'r_full_claim',
        type: 'customboolean',
        label: 'Reclamación de',
        dictionary: [
          { value: true, label: 'Cantidad' },
          { value: false, label: 'Nulidad de interés (No se muestra cantidad de calculadora en el informe)' }
        ],
        disabled: !vm.isEditable
      },
      {
        id: 'r_is_microcredit',
        type: 'customboolean',
        label: 'Tipo servicio',
        dictionary: [
          { value: false, label: 'Tarjeta Revolving' },
          { value: true, label: 'Micropréstamo' }
        ],
        disabled: !vm.isEditable
      },
      {
        id: 'r_contract_number',
        type: 'label',
        label: 'Número de contrato',
        pattern: '[\\w\\s.-]{0,30}',
        title: 'Caracteres alfanuméricos, guiones, puntos y espacios. Hasta 30 caracteres.',
        disabled: !vm.isEditable
      },
      {
        id: 'r_contract_date',
        type: 'date',
        label: 'Fecha contrato de la tarjeta',
        disabled: !vm.isEditable
      },
      {
        id: 'r_credit_card_number',
        type: 'label',
        label: 'Enumeración/Terminación tarjeta',
        pattern: '([0-9]{4}|[0-9]{16,19})?',
        title: 'Terminación: 4 dígitos. Enumeración: 16-19 dígitos.',
        disabled: !vm.isEditable
      },
      { id: 'r_tae_interest', type: 'percentage', label: 'TAE', disabled: !vm.isEditable},
      { id: 'r_initial_loan', type: 'currency', label: 'Crédito inicial', disabled: !vm.isEditable },
      {
        id: 'r_credit_card_fixed_rate',
        type: 'currency',
        label: 'Cuota tarjeta',
        disabled: !vm.isEditable
      },
      {
        id: 'r_has_fee_for_debt_claim',
        type: 'boolean',
        label: 'Comisión por reclamación de posiciones deudoras',
        disabled: !vm.isEditable
      },
      { id: 'r_has_delay_penalty_fees', type: 'boolean', label: 'Intereses de demora', disabled: !vm.isEditable },
      {
        id: 'r_has_insurance_attached',
        type: 'boolean',
        label: 'Contrato de seguro anexo a crédito concedido',
        disabled: !vm.isEditable
      },
      { id: 'r_usury', type: 'boolean', label: 'Usura', disabled: !vm.isEditable },
      {
        id: 'r_financial_entity',
        type: 'picklist',
        label: 'Entidad comercializadora',
        service: 'bankEntity',
        entity: 'financialentity',
        disabled: !vm.isEditable
      },
      {
        id: 'r_credit_card',
        type: 'picklist',
        displayFieldName: 'type',
        label: 'Tipo de tarjeta',
        service: 'creditcardEntity',
        disabled: !vm.isEditable
      },
      {
        id: 'r_issuing_bank',
        type: 'picklist',
        label: 'Banco Emisor',
        service: 'issuingbankEntity',
        disabled: !vm.isEditable
      }
    ];
  }
}
