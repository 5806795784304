/*
Reclamador dataLoader factory
==========================================

Wrapper for most common Providers calls in the CRM
Implements promises, caching and mixings to simplify most complex calls

USAGE:
Load the service and call with the names of the Providers you need
dataLoader('accidentlawyers') pr dataLoader(['accidentlawyers', 'banklawyers'])

The call returns the usual angular Provider (with the $promise and $resolved values) data
or the single requests and for multiple requests (['req1', 'req2']) a similar object with
a global promise a keys for each request with the original Provider.

Anyway, you can all 'old style':
var layers = dataLoader('accidentlawyers')

and when the requests end (sucesfully), you will have lawyers.object with the data,
or use them with promises (better) like this:

dataLoader('accidentlawyers').$promise
    .then(function(response){
        lawyers = response
    }, function(e){
        console.log('Error processing request: ' + e)
    })

The factories requests are defined in factoriesData, with the callable factory, and
a cache value that defines if the data of the calls is saved for future request.
You can pass a second parameter with global configuration options and configuration
for each request in multiple request. The most complex request would be like this.

dataLoader(['accidentlawyers', 'externalCourtLawyers'],
        { refresh: true, externalCourtLawyers: { requestData: { query: 'is_bank=True' } } } }).$promise
    .then(function(response){
        accidentlawyers = response['accidentlawyers']
        externalCourtLawyers = response['externalCourtLawyers']
        console.log(response)
    }, function(e){
        console.log('Error processing request: ' + e)
    })

Finally, you can call the function with a mixin that will expanded to a call
to multiple providers.

dataLoader('allLawyers')
is the same as
dataLoader(['lawyers', 'banklawyers', 'accidentlawyers', 'phonelawyers', 'laborallawyers', 'taxeslawyers', 'negligenceslawyers', 'solawyers'])
*/
import angular from 'angular';

dataLoader.$inject = [
  'Lawyer',
  'BankLawyer',
  'ObligationToMakeLawyer',
  'AccidentLawyer',
  'PhoneLawyer',
  'LaboralLawyer',
  'TaxesLawyer',
  'NegligencesLawyer',
  'ForeignerServicesLawyer',
  'ManagementLawyer',
  'SOLawyer',
  'SGLawyer',
  'OtherPartLawyer',
  'OtherLawyer',
  'CourtLawyer',
  'ExternalCourtLawyer',
  'ProceduralLawyerService',
  'AirlineSalesman',
  'BankSalesman',
  'AccidentSalesman',
  'LaboralSalesman',
  'TaxesSalesman',
  'NegligencesSalesman',
  'ForeignerServicesSalesman',
  'ManagementSalesman',
  'SGSalesman',
  'SOSalesman',
  'ClaimState',
  'UnpaidState',
  'Segment',
  'IncentiveState',
  'SalesClaimState',
  'ClaimType',
  'DocumentType',
  'ClaimSource',
  'LawyerStatesCount',
  'LegalExpert',
  'HealthCompany',
  'CostTypes',
  'Province',
  'GeoProvince',
  'GeoRegion',
  'LegalFileStatisticsGroups',
  'Court',
  'Procurador',
  'BankDictionaryService',
  'BankEntityService',
  'StatesWithPreviewEmail',
  'SODebtType',
  'SOLiqType',
  'TrialMunicipality',
  'DocxTemplates',
  'InvestorPortfolio',
  'BoughtPortfolio',
  'ProceduralPhaseService',
  'VCType',
  'VCInternalType',
  'VCServiceTiming',
  'ClaimPhases',
  'CreditCardService',
  'IssuingBankService',
  '$q'
];

export default function dataLoader(
  Lawyer,
  BankLawyer,
  ObligationToMakeLawyer,
  AccidentLawyer,
  PhoneLawyer,
  LaboralLawyer,
  TaxesLawyer,
  NegligencesLawyer,
  ForeignerServicesLawyer,
  ManagementLawyer,
  SOLawyer,
  SGLawyer,
  OtherPartLawyer,
  OtherLawyer,
  CourtLawyer,
  ExternalCourtLawyer,
  ProceduralLawyerService,
  AirlineSalesman,
  BankSalesman,
  AccidentSalesman,
  LaboralSalesman,
  TaxesSalesman,
  NegligencesSalesman,
  ForeignerServicesSalesman,
  ManagementSalesman,
  SGSalesman,
  SOSalesman,
  ClaimState,
  UnpaidState,
  Segment,
  IncentiveState,
  SalesClaimState,
  ClaimType,
  DocumentType,
  ClaimSource,
  LawyerStatesCount,
  LegalExpert,
  HealthCompany,
  CostTypes,
  Province,
  GeoProvince,
  GeoRegion,
  LegalFileStatisticsGroups,
  Court,
  Procurador,
  BankDictionaryService,
  BankEntityService,
  StatesWithPreviewEmail,
  SODebtType,
  SOLiqType,
  TrialMunicipality,
  DocxTemplates,
  InvestorPortfolio,
  BoughtPortfolio,
  ProceduralPhaseService,
  VCType,
  VCInternalType,
  VCServiceTiming,
  ClaimPhases,
  CreditCardService,
  IssuingBankService,
  $q
) {
  // pack usual request
  let mixins = {
    allLawyers: [
      'lawyers',
      'banklawyers',
      'accidentlawyers',
      'phonelawyers',
      'laborallawyers',
      'taxeslawyers',
      'negligenceslawyers',
      'solawyers',
      'foreignerServiceslawyers',
      'legalserviceslawyers'
    ]
  };

  let factoriesData = {
    // lawyers verticals
    lawyers: { factory: Lawyer, cache: true },
    airlinelawyers: { factory: Lawyer, cache: true },
    banklawyers: { factory: BankLawyer, cache: true },
    obligationtomakelawyers: { factory: ObligationToMakeLawyer, cache: true },
    accidentlawyers: { factory: AccidentLawyer, cache: true },
    phonelawyers: { factory: PhoneLawyer, cache: true },
    laborallawyers: { factory: LaboralLawyer, cache: true },
    taxeslawyers: { factory: TaxesLawyer, cache: true },
    negligenceslawyers: { factory: NegligencesLawyer, cache: true },
    foreignerServiceslawyers: { factory: ForeignerServicesLawyer, cache: true },
    managementslawyers: { factory: ManagementLawyer, cache: true },
    solawyers: { factory: SOLawyer, cache: true },
    otherpartlawyers: { factory: OtherPartLawyer, cache: true },
    genericlawyers: { factory: OtherLawyer, cache: true },
    legalserviceslawyers: { factory: SGLawyer, cache: true },
    proceduralLawyers: { factory: ProceduralLawyerService, cache: true},

    
    // más lawyers
    externalCourtLawyers: { factory: ExternalCourtLawyer, cache: true },
    courtlawyers: { factory: CourtLawyer, cache: true },
    procuradores: { factory: Procurador, cache: true },
    externalcourtlawyers_forbanking: {
      factory: ExternalCourtLawyer,
      cache: true,
      requestData: { query: 'is_bank=True' }
    },
    externalcourtlawyers_forairlines: {
      factory: ExternalCourtLawyer,
      cache: true,
      requestData: { query: 'is_airline=True' }
    },
    externalcourtlawyers_forlaboral: {
      factory: ExternalCourtLawyer,
      cache: true,
      requestData: { query: 'is_laboral=True' }
    },
    externalcourtlawyers_foraccidents: {
      factory: ExternalCourtLawyer,
      cache: true,
      requestData: { query: 'is_accident=True' }
    },
    externalcourtlawyers_forphone: {
      factory: ExternalCourtLawyer,
      cache: true,
      requestData: { query: 'is_phone=True' }
    },
    externalcourtlawyers_fornegligences: {
      factory: ExternalCourtLawyer,
      cache: true,
      requestData: { query: 'is_negligences=True' }
    },
    externalcourtlawyers_fortaxes: {
      factory: ExternalCourtLawyer,
      cache: true,
      requestData: { query: 'is_taxes=True' }
    },
    
    // Lawyers count
    accidentlawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'accident-claim'
      }
    },
    banklawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'bank-claim'
      }
    },
    negligenceslawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'negligences'
      }
    },
    laborallawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'laboral'
      }
    },
    taxeslawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'taxes'
      }
    },
    solawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'ley-segunda-oportunidad'
      }
    },
    phonelawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'phone-claim'
      }
    },
    foreignerServiceslawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'foreignerservices'
      }
    },
    obligationtomakelawyersStatesCount: {
      factory: LawyerStatesCount,
      cache: true,
      requestData: {
        segment: 'obligationtomake-claim'
      }
    },
    
    // salesmen
    salesmen: { factory: AirlineSalesman, cache: true },
    airlinesalesmen: { factory: AirlineSalesman, cache: true },
    banksalesmen: { factory: BankSalesman, cache: true },
    accidentsalesmen: { factory: AccidentSalesman, cache: true },
    laboralsalesmen: { factory: LaboralSalesman, cache: true },
    taxessalesmen: { factory: TaxesSalesman, cache: true },
    negligencessalesmen: { factory: NegligencesSalesman, cache: true },
    genericsalesmen: { factory: ForeignerServicesSalesman, cache: true },
    phonesalesmen: { factory: ForeignerServicesSalesman, cache: true },
    sosalesmen: { factory: SOSalesman, cache: true },
    legalexperts: { factory: LegalExpert, cache: true },
    foreignerServicessalesmen: { factory: ForeignerServicesSalesman, cache: true },
    managementssalesmen: { factory: ManagementSalesman, cache: true },
    legalservicessalesmen: { factory: SGSalesman, cache: true },
  
    
    //claimstates
    claimstates: { factory: ClaimState, cache: true, requestData: { costs: false, limit: 1000 } },
    unpaidstates: { factory: UnpaidState, cache: true },
    costsclaimstates: { factory: ClaimState, cache: true, requestData: { costs: true } },
    salesclaimstates: { factory: SalesClaimState, cache: true },
    
    statesWithPreviewEmail: { factory: StatesWithPreviewEmail, cache: true },

    // other data
    claimtypes: { factory: ClaimType, cache: true },
    claimTypesForNewCase: { factory: ClaimType, cache: true, 
      requestData: { 
        is_active: true, type__in: ['airline', 'bank', 'laboral', 'managements', 'taxes', 'legalservices'] 
      } 
    },
    claimtypesLaboral: { factory: ClaimType, cache: true, requestData: { type: 'laboral' } },
    claimtypesBank: { factory: ClaimType, cache: true, requestData: { type: 'bank' } },
    claimPhases: { factory: ClaimPhases, cache: true },
    legalfilestatisticsgroups: { factory: LegalFileStatisticsGroups, cache: true },
    claimsources: { factory: ClaimSource, cache: true },
    segments: { factory: Segment, cache: true },
    incentiveStates: { factory: IncentiveState, cache: true },
    costtypes: { factory: CostTypes, cache: true },
    healthcompanies: { factory: HealthCompany, cache: true },
    trialMunicipalities: {factory: TrialMunicipality, cache: true},
    docxtemplates: {factory: DocxTemplates, cache:true},

    documenttypes: { factory: DocumentType, cache: true },
    legalfileDocumentTypes: { factory: DocumentType, cache: true, requestData: { type: 'aircase'} },
    courts: { factory: Court, cache: true },
    provinces: { factory: Province, cache: true },
    geoprovinces: { factory: GeoProvince, cache: true },
    georegions: { factory: GeoRegion, cache: true },
    debttypes: { factory: SODebtType, cache: true },
    liqtypes: { factory: SOLiqType, cache: true },
    investorPortfolio: { factory: InvestorPortfolio, cache:true },
    boughtPortfolio: {factory: BoughtPortfolio, cache:true },
    proceduralPhases: { factory: ProceduralPhaseService, cache: true},

    // Dictionaries
    bankDictionary: { factory: BankDictionaryService, cache: true },
    bankEntity: { factory: BankEntityService, cache: false },
    videocalltype: { factory: VCType, cache: true },
    videocallinternaltype: { factory: VCInternalType, cache: true },
    VCServiceTiming: { factory: VCServiceTiming, cache: true },
    creditcardEntity: { factory: CreditCardService, cache: false },
    issuingbankEntity: { factory: IssuingBankService, cache: false }
    

  };

  function loadSource(source, options) {
    if (!factoriesData[source]) {
      throw new Error('[dataLoader-factory] Unknown dataSource ' + source);
    }

    // Return cached data (promise or real data)
    if (factoriesData[source].data && factoriesData[source].cache && !(options && options.refresh)) {
      for (var i = factoriesData[source].data.length - 1; i >= 0; i--) {
        // check for request without settings
        if((!options || !options.requestData) && !factoriesData[source].data[i].requestData) {
          return factoriesData[source].data[i].response;
        }

        // check for request with settings
        if(options && angular.equals(factoriesData[source].data[i].requestData, options.requestData)) {
          return factoriesData[source].data[i].response;
        }
      }
    }

    // Request the data to the factory
    const settings = options && options.requestData ? options.requestData : factoriesData[source].requestData;
    const promise = factoriesData[source].factory.get(settings);

    // cache data with request options
    if(!factoriesData[source].data) factoriesData[source].data = [];
    factoriesData[source].data.push({
      response: promise,
      requestData: options && options.requestData ? options.requestData : null
    });

    return promise;
  }

  function loadMultiple(requestArray, options) {
    const promises = [];
    const finalPromise = $q.defer(); // output promise
    
    // return an object similar to the angular Resource:
    // with the $promise and $resolved values and fill them with the data once the data is ready
    const output = {
      $promise: finalPromise.promise,
      $resolved: false
    };

    for (var i = requestArray.length - 1; i >= 0; i--) {
      var settings = options ? Object.assign({}, options, options[requestArray[i]]) : null;
      promises.unshift(loadSource(requestArray[i], settings).$promise);
    }

    $q.all(promises)
      .then(responses => {
        // fill the output object with each requested data as a key in the object
        for (var j = requestArray.length - 1; j >= 0; j--) {
          output[requestArray[j]] = responses[j];
        }
        // resolve promise!
        finalPromise.resolve(output);
      })
      .catch(e => {
        // something happened
        finalPromise.reject(e);
      });

    return output;
  }

  return (req, options) => {
    if (typeof req === 'string') {
      if (mixins[req]) {
        return loadMultiple(mixins[req], options);
      }
      return loadSource(req, options);
    } else if (Array.isArray(req)) {
      return loadMultiple(req, options);
    }

    throw new Error('[dataLoader-factory] Invalid request data: ' + req);
  };
}
