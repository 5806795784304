import ANSWERS from './answers';

const SEGMENT_ID = 2,
  MODEL_PROVIDER = 'BankClaim';

const CONIFG = {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'BA',
  ALIAS: 'bank',
  VERBOSE_NAME: 'Reclamación de banca',
  permissions: {
    downloadCSVPermission: 'segment-perobject-BA-crm_download_csv'
  },
  CLAIM_DICTIONARIES: {
    salesman: 'banksalesmen',
    users: 'banklawyers',
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    claimTypes: {
      entity: 'claimtypes',
      data: { type: 'bank' }
    },
    sources: 'claimsources',
    courtlawyers: 'courtlawyers',
    bankDictionary: 'bankDictionary',
    procuradores: {
      entity: 'procuradores',
      data: { is_bank: true }
    },
    otherpartlawyers: 'otherpartlawyers',
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: MODEL_PROVIDER }
    },
    provinces: 'provinces',
    docxtemplates: {
      entity: 'docxtemplates',
      data: { active: true, segments__id: SEGMENT_ID, target_holder: 'claim' }
    },
    boughtPortfolio: {
      entity: 'boughtPortfolio',
      data: { allowed_segments_for_bought_portfolio__id: SEGMENT_ID }
    }
  },
  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/bank/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/bank/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Indemnización',
      indexKey: 'indemnizacion',
      template: 'js/modules/segments/bank/partials/detail/amount.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/bank/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Invitaciones',
      indexKey: 'invitaciones',
      template: 'js/modules/segments/bank/partials/detail/invitations.html',
      select: null
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: '/partials/common/detail/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true },
    { headerName: 'Cliente', fieldName: 'client_surnames', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true, hidden: true },
    { headerName: 'Provincia cliente', fieldName: 'client_province', sortable: true, hidden: true },
    { headerName: 'Tlfno', fieldName: 'client_phone', sortable: true, hidden: true },
    {
      headerName: 'Asumimos costas',
      fieldName: 'assume_legal_costs',
      sortable: true,
      hidden: true
    },
    { headerName: 'Costas', fieldName: 'has_legal_costs', sortable: true },
    { headerName: 'Finalizada', fieldName: 'finished', sortable: true },
    { headerName: 'Fecha Finalizada', fieldName: 'date_finished', sortable: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Comercial', fieldName: 'salesman', sortable: true },
    { headerName: 'Validado por', fieldName: 'validator_lawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    {
      headerName: 'Asistencia del cliente al juicio',
      fieldName: 'client_attendance',
      sortable: true
    },
    {
      headerName: 'Solicitud de reclamación de gastos enviada',
      fieldName: 'new_claim_request_sent',
      sortable: true
    },
    { headerName: 'Estado C.', fieldName: 'sales_state_name', sortable: true },
    { headerName: 'Fase', fieldName: 'phase_name', sortable: true },
    { headerName: 'Fuente', fieldName: 'source_name', sortable: true },
    { headerName: 'Cómo nos conoció', fieldName: 'how_met_us', sortable: true },
    { headerName: 'Fuente invitador', fieldName: 'invited', sortable: true, hidden: true },
    { headerName: 'Banco', fieldName: 'bank', sortable: true },
    { headerName: 'Campaña', fieldName: 'campaign_name', sortable: true, hidden: true },
    { headerName: 'SEM', fieldName: 'has_sem_gclid', sortable: true, hidden: true },
    { headerName: 'SEM (Valor)', fieldName: 'sem_gclid', sortable: true, hidden: true },
    { headerName: 'Network', fieldName: 'sem_network', sortable: true, hidden: true },
    { headerName: 'Placement', fieldName: 'sem_placement', sortable: true, hidden: true },
    { headerName: 'Campaign', fieldName: 'sem_campaign', sortable: true, hidden: true },
    {
      headerName: 'Ultimo cambio estado',
      fieldName: 'laststatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado',
      fieldName: 'laststatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentario cambio estado',
      fieldName: 'laststatecomment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ultimo cambio estado comercial',
      fieldName: 'lastsalesstatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    { headerName: 'Procurador', fieldName: 'procurador_name', sortable: true },
    {
      headerName: 'Fecha de Cliente',
      fieldName: 'validated_state_date',
      sortable: true,
      hidden: true
    },
    { headerName: 'Fecha de aceptación', fieldName: 'date_accepted', sortable: true, hidden: true },
    {
      headerName: 'Fecha de cambio a "Pendiente citación notario"',
      fieldName: 'date_pending_notary_notice',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha de cambio a "Pendiente citación Apud Acta"',
      fieldName: 'date_pending_apud_acta_notice',
      sortable: true,
      hidden: true
    },
    { headerName: 'Abogado validación', fieldName: 'lawyer_valid', sortable: true, hidden: true },
    {
      headerName: 'Fecha de transferencia',
      fieldName: 'date_transfer',
      sortable: true,
      hidden: true
    },
    { headerName: 'Último comentario', fieldName: 'last_comment', sortable: true, hidden: true },
    {
      headerName: 'Usuario últ. comentario',
      fieldName: 'last_comment_user',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha últ. comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: true
    },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'date_updated', sortable: true },
    { headerName: 'Abogado de la parte contraria', fieldName: 'other_part_lawyer', sortable: true },
    { headerName: 'Cantidad ofrecida mediación', fieldName: 'mediation_amount', sortable: true },
    { headerName: 'Fecha envío mediación', fieldName: 'mediation_mail_date', sortable: true },
    { headerName: 'Numero documentos', fieldName: 'num_docs', sortable: true, hidden: true },
    { headerName: 'Cantidad reclamada', fieldName: 'compensation', sortable: true, hidden: true },
    {
      headerName: 'Primer vertical',
      fieldName: 'first_claim_segment',
      sortable: true,
      hidden: true
    },
    { headerName: 'Primer producto', fieldName: 'first_claim_type', sortable: true, hidden: true },
    {
      headerName: 'Documentos pendientes',
      fieldName: 'pending_docs',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentarios documentos pendientes',
      fieldName: 'pending_docs_comments',
      sortable: true,
      hidden: true
    },
    { headerName: 'Nº Expediente', fieldName: 'legalfile_id', sortable: true, hidden: true },
    {
      headerName: 'Estado de las costas',
      fieldName: 'costs_state_name',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha últ. cambio estado de las costas',
      fieldName: 'costs_state_last_change',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha alta cliente',
      fieldName: 'client_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha primera reclamación válida',
      fieldName: 'first_claim_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'SLAs vencidos',
      fieldName: 'applied_lawyer_type_sla',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'SLAs a punto de vencer',
      fieldName: 'expiration_lawyer_type_sla_datetime',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tipo de facturación',
      fieldName: 'is_immediate',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha a facturar',
      fieldName: 'billing_date',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cantidad acuerdo prejudicial',
      fieldName: 'agreement_amount',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Fecha cesión',
      fieldName: 'transfered_to_investor_date',
      sortable: true
    },
    {
      headerName: 'Cartera',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Banco Emisor Revolving',
      fieldName: 'revolving_issuing_bank',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    },
    {
      headerName: 'Tipo de reclamación gastos',
      fieldName: 'expenses_type',
      sortable: true
    }
  ],
  filterFieldInfo: {
    has_legal_costs: { label: 'Costas', type: 'boolean' },
    bank: { label: 'Banco', json_value: 'true', use_field: 'name' },
    floor_clause_reference_type: {
      label: 'Tipo de referencia cl. suelo',
      select_id: 'claim-floor-clause-reference-type-select'
    },
    applied_lawyer_type_sla: { label: 'SLAs vencidos', type: 'boolean' },
    expiration_lawyer_type_sla_datetime__lte: { label: 'SLAs a punto de vencer', type: 'date' },
    costs_state_id: { label: 'Costas' },
    revolving_issuing_bank: { label: 'Banco Emisor (Revolving)', json_value: 'true', use_field: 'name'},
    expenses_type: { label: 'Tipo gasto reclamado', select_id: 'expenses_type_select'}
  },
  CLAIM_PRODUCT_FIELDS: {
    // Revolving
    56: [
      {
        id: 'r_full_claim',
        type: 'customboolean',
        label: 'Reclamación de',
        dictionary: [
          { value: true, label: 'Cantidad' },
          { value: false, label: 'Nulidad de interés (No se muestra cantidad de calculadora en el informe)' }
        ]
      },
      {
        id: 'r_is_microcredit',
        type: 'customboolean',
        label: 'Tipo servicio',
        dictionary: [
          { value: false, label: 'Tarjeta Revolving' },
          { value: true, label: 'Micropréstamo' }
        ]
      },
      {
        id: 'r_contract_number',
        type: 'label',
        label: 'Número de contrato',
        pattern: '[\\w\\s.-]{0,30}',
        title: 'Caracteres alfanuméricos, guiones, puntos y espacios. Hasta 30 caracteres.'
      },
      {
        id: 'r_contract_date',
        type: 'date',
        label: 'Fecha contrato de la tarjeta'
      },
      {
        id: 'r_credit_card_number',
        type: 'label',
        label: 'Enumeración/Terminación tarjeta',
        pattern: '([0-9]{4}|[0-9]{16,19})?',
        title: 'Terminación: 4 dígitos. Enumeración: 16-19 dígitos.'
      },
      { id: 'r_tae_interest', type: 'percentage', label: 'TAE' },
      { id: 'r_initial_loan', type: 'currency', label: 'Crédito inicial' },
      {
        id: 'r_credit_card_fixed_rate',
        type: 'currency',
        label: 'Cuota tarjeta'
      },
      {
        id: 'r_has_fee_for_debt_claim',
        type: 'boolean',
        label: 'Comisión por reclamación de posiciones deudoras'
      },
      { id: 'r_has_delay_penalty_fees', type: 'boolean', label: 'Intereses de demora' },
      {
        id: 'r_has_insurance_attached',
        type: 'boolean',
        label: 'Contrato de seguro anexo a crédito concedido'
      },
      { id: 'r_usury', type: 'boolean', label: 'Usura' }
    ]
  }
};

Object.assign(CONIFG, ANSWERS);

export default CONIFG;
