ClaimDetailProductManageCtrl.$inject = [];

export default function ClaimDetailProductManageCtrl() {
  const vm = this;

  vm.claimTypeId = null;
  vm.showProductRevolving = false;

  function manageProducts(claimTypeId) {
    if (claimTypeId === 60) {
      vm.showProductRevolving = true;
    }
  }

  vm.$onChanges = changes => {
    if (changes.claimTypeId) {
      const { currentValue } = changes.claimTypeId;
      if (currentValue === undefined) return;

      manageProducts(vm.claimTypeId);
    }
  };
}
