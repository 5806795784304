ObligationToMakeClaimListCtrl.$inject = ['$scope', '$controller', 'ObligationToMakeConstants'];

export default function ObligationToMakeClaimListCtrl($scope, $controller, ObligationToMakeConstants) {
  const vm = this;
  const claimDetailTabs = {};
  ObligationToMakeConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: ObligationToMakeConstants.ALIAS + '-claims',

    itemsPath: 'obligation-to-make-claim/list/',
    listPath: 'obligationtomake-claims/list/filter/',
    listPathNoFIlter: 'obligationtomake-claims',
    defaultColumnDef: ObligationToMakeConstants.defaultColumnDef,
    filterFieldInfo: ObligationToMakeConstants.filterFieldInfo
  };
  const dataLoaderKeys = ['claimstates', 'claimtypes'];

  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(ObligationToMakeConstants.SEGMENT_ID, ObligationToMakeConstants.ALIAS, ObligationToMakeConstants.permissions, dataLoaderKeys);
}
