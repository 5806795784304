/**
 * Super-controller to handle lists of elements served by
 * django haystack app. Provides & simplifies column management, filters,
 * pagination, and all the comunication with the server. A few different
 * structures are mixed here for the differents parts of the controller:
 * localstorage, cookies, configuraion objects or fixed templates
 *
 * To use this in a controller you require:
 * - Data from the route:
 *   - Optional filter from querystring parameters as routeParam variable 'filter'
 *   - baseListSettings, layoutSetting & segmentId from route definition
 *     The baseListSettings variables are:
 *       - typeList - unique ifentifier for the list
 *       - templatesFolder - identifier to find custom templates
 *       - typeTitle, title, listResultsText - some human strings
 *
 * - Data from the parent Controller:for the
 *   - itemsPath - endpoint to request the data
 *   - listPath - angular path list with filters
 *   - listPathNoFIlter - angular path with the same list without filters
 *   - filterFieldInfo - Filters information
 *   - d data: efaultColumnDef - Table columns information
 *
 * - Templates:
 *   - /partials/{{segment}}/claim-list-table.html -> Table HTML, in sync with defaultColumnDef
 *   - /partials/{{segment}}/claim-list-filters.html -> Filters HTML, in sync with filterFieldInfo
 *
 * - Intialization:
 *   - Call initBaseList() from the parent controller
 *   - Async data is used mostly to get human data for filters and their badges. To update when
 *     it's ready call manually prepareFilters()
 *
 * ** FLOW:
 * ** COLUMNS:
 * Columns management follow a mixed approach with the data from the defaultColumnDef
 * array and also the html from claim-list-table.html. The user column preferences are
 * stored in localstorage and merged with the data from defaultColumnDef to keep the data
 * updated.
 *
 * The defaultColumnDef objects should contain:
 * - headerName => header name
 * - fieldName => the key in the results object
 * - sortable => allows the columna to be sorted
 * - alwaysVisible => ignores hidden values
 * - hidden [optional] => initial hidden value
 *
 * ** FILTERS:
 * We provide a tool to prepare filters and send to the endpoint. We require the
 * filterFieldInfo object and the html for the modal
 * {
 *    filterName: {
        label => displayed in filter badges
        type => defines special cases: mostly date to process dates as ISO
        json_value => uses value from key defitnd in use_field
        use_field => see json_value
        select_id => Get's human name from select with this id (for async data)
        column & order => not sure where are those used
      },
  }
 *
 * SEGMENT DATA HANDLING:
 * The `handleSegmentData` function simplifies the process of loading segment-specific
 * data asynchronously. It is invoked as part of the initialization of a list, allowing
 * dynamic fetching of related resources like claim states, claim types, and more.
 *
 * ** USAGE:
 * To use `handleSegmentData`, ensure you provide the following:
 * - `segmentID`: The identifier for the segment whose data is being loaded.
 * - `segmentAlias`: A string alias for the segment, used to structure the data keys.
 * - `keysToLoad` (optional): An array of specific keys defining which data to fetch.
 *   If omitted, all default keys will be fetched.
 *
 * EXAMPLE:
 * Below is an example of how to use `initBaseList` with `handleSegmentData`:
 *
 * const dataLoaderKeys = ['claimstates'];
 * vm.initBaseList(SEGMENT_ID, ALIAS, permissions, dataLoaderKeys);
 */

import moment from 'moment';

BaseListCtrl.$inject = [
  'vm',
  '$q',
  '$scope',
  '$http',
  'dataLoader',
  '$timeout',
  'serverAddress',
  '$routeParams',
  'notifications',
  'constantsFactory',
  'select2Factory',
  '$route',
  '$location',
  '$window',
  '$localStorage',
  'DownloadCSVService'
];

export default function BaseListCtrl(
  vm,
  $q,
  $scope,
  $http,
  dataLoader,
  $timeout,
  serverAddress,
  $routeParams,
  notifications,
  constantsFactory,
  select2Factory,
  $route,
  $location,
  $window,
  $localStorage,
  DownloadCSVService
) {
  const genericObj = {
    typeList: 'claims',
    templateListTable: '',
    templateListFilter: '',
    title: 'Reclamaciones',
    listResultsText: 'reclamaciones'
  };
  const baseListSettings = $route.current.$$route.baseListSettings
    ? $route.current.$$route.baseListSettings
    : genericObj;
  const INITIAL_STATE = {
      // read settings from router or use defaults
      baseListSettings,
      requiredPermissions: {},
      layoutSettings: $route.current.$$route.layoutSetting || null,

      columnDef: [],
      defaultColumnDef: [],
      sortedColumn: {},

      enableFilters: true,

      busy: false,

      dateOptions: constantsFactory.getDateOptions(),

      isExportingCSV: false,

      items: {}, // final items
      meta: {},

      itemsPath: null, // list API endpoint path
      listPath: null, // baseList path to full list with filters
      listPathNoFIlter: null, // baseList path without filters (TODO => whyyyy)

      /* Initials */
      pageSize: '20', // defaults in case not set into localStorage
      currentPage: 0, // Active page
      nPages: 1, // set by API results length

      /* filters state */
      filterFieldInfo: {}, // initial filter definition (required by subControllers)
      currentFilters: {}, // filters object used by filter badges
      newFilters: {}, // filters object used by filter modal

      enableFiltering: true, // save filters fold status
      displaycolumnsFilters: true, // setting => enable column filter dropdown
      segmentToFilter: '', // active segment (lawfiles)
      documentsForCopying: [],

      LOCALSTORAGE_COLUMN_NAME_SUFIX: '-claim-list-table-columns',
      TABLE_SUFIX: '-claim-list-table',

      commonClaimFilters: {
        type_id: { label: 'Tipo', select_id: 'claim-type-select' },
        finished: { label: 'Terminada' },
        state_id: { label: 'Estado (Legal)', select_id: 'claim-state-select' },
        sales_state_id: {
          label: 'Estado (Comercial)',
          select_id: 'claim-salesstate-select',
          column: 'sState.id'
        },
        date_updated__gte: {
          label: 'Fecha modificado desde',
          type: 'date',
          column: 'claim.date_updated',
          order: '>='
        },
        date_updated__lte: {
          label: 'Fecha modificado hasta',
          type: 'date',
          column: 'claim.date_updated',
          order: '<='
        },
        date_added__gte: {
          label: 'Fecha creado desde',
          type: 'date',
          column: 'claim.date_added',
          order: '>='
        },
        date_added__lte: {
          label: 'Fecha creado hasta',
          type: 'date',
          column: 'claim.date_added',
          order: '<='
        },
        date_finished__gte: {
          label: 'Fecha finalizado desde',
          type: 'date',
          column: 'claim.date_finished',
          order: '>='
        },
        date_finished__lte: {
          label: 'Fecha finalizado hasta',
          type: 'date',
          column: 'claim.date_finished',
          order: '<='
        },
        source_id: { label: 'Fuente', select_id: 'claim-source-select' },
        campaign__name: { label: 'Campaña', json_value: 'true', use_field: 'name' },
        transfered_to_investor: {
          label: 'Cedida',
          column: 'transfered_to_investor',
          type: 'boolean'
        },
        investor_id: {
          label: 'Cartera de inversiones',
          select_id: 'claim-investor-select',
          column: 'investor.id'
        },
        phase_name: {
          label: 'Fase',
          select_id: 'claim-phase-select'
        },
        transfered_to_investor_date__gte: {
          label: 'Fecha de cesión desde',
          type: 'date',
          column: 'claim.transfered_to_investor_date',
          order: '>='
        },
        transfered_to_investor_date__lte: {
          label: 'Fecha de cesión hasta',
          type: 'date',
          column: 'claim.transfered_to_investor_date',
          order: '<='
        },
        bought_from_portfolio: {
          label: 'Comprada',
          column: 'bought_from_portfolio',
          type: 'boolean'
        },
        bought_portfolio_id: {
          label: 'Cartera comprada',
          select_id: 'bought-portfolio-select',
          column: 'bought_portfolio.id'
        },        
        // from here, not real filters, used by panels queries
        client_name: { label: 'Nombre cliente', type: 'text' },
        client_surnames: { label: 'Apellidos cliente', type: 'text' },
        lawyer: { label: 'Abogado' },
        lawyer__username: { label: 'Abogado' },
        salesman: { label: 'Comercial' }
      }
    },
    METHODS = {
      initBaseList,

      // columns
      initColumnDef,
      updateHiddenColumns,
      resetHiddenColumns,
      saveColumnData,
      showAllColumns,
      sortColumn,
      hideColumns,

      // pagination
      prevPage,
      nextPage,
      initPageSizeDef,

      // filtering
      removeFilter,
      clearFilters,
      applyFilters,
      updateSegmentItems,

      // main list function
      updateItems,
      requestPage,
      onKeyPress,

      // helpers => allow to overwritethem
      getQueryData,
      exportCSV,

      sortByName,
      fixFilterDisplay,

      parseUrlParameters,
      getSearchQueryParams,
      prepareFilters,

      // HORROR
      selectAgencies: select2Factory.selectAgencies,
      selectCampaign: select2Factory.selectCampaign,
      selectAirlines: select2Factory.selectAirlines,
      selectSalesman: select2Factory.selectSalesman,
      selectNotary: select2Factory.selectNotary,
      selectAirport: select2Factory.selectAirport,
      selectCourts: select2Factory.selectCourts,
      changeBank: select2Factory.changeBank,
      selectAgainst: select2Factory.selectAgainst,
      selectCity: select2Factory.selectCity,
      changeIssuingBank: select2Factory.changeIssuingBank,

      // Permissions
      setRequiredPermissions
    };

  Object.assign(vm, INITIAL_STATE, METHODS);

  /** METHODS */

  /**
   * common initialization
   * @return {void}
   */
  function initBaseList(segmentID, segmentAlias, permissions, dataLoaderKeys = undefined) {
    vm.setRequiredPermissions(permissions);
    vm.filterFieldInfo = Object.assign(vm.filterFieldInfo, vm.commonClaimFilters);

    if ($window.rclmng_globals) {
      $scope.$on('ngRepeatFinished', () => {
        $window.rclmng_globals.ux.baseListScroll.init();
        vm.updateHiddenColumns();
      });
    }

    vm.initPageSizeDef();
    vm.initColumnDef();
    vm.parseUrlParameters();
    vm.requestPage();

    function handleSegmentData(id, alias, keysToLoad = null) {
      const allKeys = [
        alias + 'lawyers',
        alias + 'salesmen',
        'claimstates',
        'claimPhases',
        'claimtypes',
        'salesclaimstates',
        'investorPortfolio',
        'claimsources',
        'boughtPortfolio'
      ];

      const selectedKeys = keysToLoad || allKeys;

      const dataMapping = {
        claimstates: { requestData: { segments__id: id, costs: false } },
        claimPhases: { requestData: { id }},
        claimtypes: { requestData: { type: alias } },
        salesclaimstates: { requestData: { segments__id: id, costs: false } },
        investorPortfolio: { requestData: { allowed_segments_for_investors__id: id } },
        boughtPortfolio: { requestData: { allowed_segments_for_bought_portfolio__id: id } }
      };

      const filteredMapping = selectedKeys.reduce((acc, key) => {
        if (dataMapping[key]) acc[key] = dataMapping[key];
        return acc;
      }, {});

      return dataLoader(selectedKeys, filteredMapping)
        .$promise.then(response => {
          if (response[alias + 'lawyers']) vm.lawyers = response[alias + 'lawyers'].objects;
          if (response[alias + 'salesmen']) vm.salesman = response[alias + 'salesmen'].objects;
          if (response.claimstates) vm.claimStates = response.claimstates.objects;
          if (response.claimtypes) vm.claimTypes = response.claimtypes.objects;
          if (response.claimPhases) vm.claimPhases = response.claimPhases;
          if (response.salesclaimstates) vm.claimSalesStates = response.salesclaimstates.objects;
          if (response.investorPortfolio) vm.investorPorfoliosList = response.investorPortfolio.objects;
          if (response.boughtPortfolio) vm.boughtPortfoliosList = response.boughtPortfolio.objects;
          if (response.claimsources) vm.sources = response.claimsources.objects;

          /** refresh filter data when all async is ready */
          vm.prepareFilters();
        })
        .catch(err => console.error(err));
    }

    if (segmentID) {
      return handleSegmentData(segmentID, segmentAlias, dataLoaderKeys);
    }

    return $q.resolve().then(() => vm.prepareFilters());
  }

  /** Load async data */

  /** UX methods */
  function prevPage() {
    if (vm.currentPage > 0) {
      vm.currentPage -= 1;
      vm.updateItems();
    }
  }

  function nextPage() {
    if (vm.currentPage + 1 < vm.nPages) {
      vm.currentPage += 1;
      vm.updateItems();
    }
  }

  function initPageSizeDef() {
    const pageSize = $localStorage.get('page-size');
    if (pageSize) {
      vm.pageSize = pageSize;
    } else {
      $localStorage.set('page-size', vm.pageSize);
    }
  }

  /**
   * Merge user column definition from localstorage with segment definition
   * @return {void}
   */
  function initColumnDef() {
    var userColumnDef = $localStorage.get(
      vm.baseListSettings.typeList + vm.LOCALSTORAGE_COLUMN_NAME_SUFIX
    );

    if (userColumnDef) {
      /**
       * if there is a user column definition, replace base definition with
       * user hidden columns
       */
      userColumnDef = JSON.parse(userColumnDef);

      vm.columnDef = vm.defaultColumnDef.map(e => {
        let col = userColumnDef.find(f => f.headerName == e.headerName);
        return col ? Object.assign({}, e, { hidden: col.hidden }) : Object.assign({}, e);
      });
    } else {
      /**
       * Use base definition
       */
      vm.columnDef = vm.defaultColumnDef.map(e => {
        return Object.assign({}, e, { hidden: e.hidden || false });
      });
    }

    vm.saveColumnData();
    vm.updateHiddenColumns();
  }

  /**
   * Update DOM table hidding columns based on columnDef
   * Yes, it counts elements in the array and display or hide that column numebr
   * @return {void}
   */
  function updateHiddenColumns() {
    var $table = $window.$('.' + vm.baseListSettings.typeList + vm.TABLE_SUFIX);
    var $cells = $table.find('td, th');

    for (var i = 0; i < vm.columnDef.length; i++) {
      var targetCell = i + 1;
      if (vm.baseListSettings.typeList == 'clients') targetCell += 2;

      var $target = $cells.filter(':nth-child(' + targetCell + ')');

      if (vm.columnDef[i].hidden) $target.hide();
      else $target.show();
    }

    $window.rclmng_globals.ux.resize();
  }

  /**
   * clear user column definition and process again
   * @return {void}
   */
  function resetHiddenColumns() {
    $localStorage.remove(vm.baseListSettings.typeList + vm.LOCALSTORAGE_COLUMN_NAME_SUFIX);
    vm.initColumnDef();

    $window.rclmng_globals.ux.clearModals();
  }

  /**
   * Set all columns as visible
   * @return {void}
   */
  function showAllColumns() {
    for (var i = 0; i < vm.columnDef.length; i++) {
      vm.columnDef[i].hidden = false;
    }

    vm.updateHiddenColumns();
    vm.saveColumnData();

    $window.rclmng_globals.ux.clearModals();
  }

  function hideColumns() {
    vm.updateHiddenColumns();
    vm.saveColumnData();
    $window.rclmng_globals.ux.clearModals();

    return false;
  }

  function saveColumnData() {
    $localStorage.set(
      vm.baseListSettings.typeList + vm.LOCALSTORAGE_COLUMN_NAME_SUFIX,
      JSON.stringify(vm.columnDef)
    );
  }

  function sortColumn(column) {
    if (column.sortable == false) return;

    vm.sortedColumn.fieldName = column.fieldName;
    vm.sortedColumn.order =
      typeof vm.sortedColumn.order === 'boolean' ? !vm.sortedColumn.order : true;

    vm.updateItems();
  }

  function clearFilters() {
    // lo normal => redirige a la vista sin filtros
    if (vm.listPathNoFIlter) return $location.path('/' + vm.listPathNoFIlter);

    vm.currentFilters = {};
    vm.currentPage = 0;
    vm.updateItems();
    return false;
  }

  function applyFilters() {
    vm.currentPage = 0;
    vm.updateItems();

    $window.rclmng_globals.ux.clearModals();
  }

  // Filtros de tipo de claim => solo en legalfiles
  function updateSegmentItems(segment) {
    vm.currentPage = 0;
    vm.segmentToFilter = segment;
    vm.updateItems();
  }

  // Remove a filter when clicking it the alert div
  function removeFilter(filter) {
    // Empty the form value
    vm.newFilters[filter] = '';

    // Remove the actual filter
    delete vm.currentFilters[filter];

    // And query again
    vm.updateItems();
  }

  /**
   * parse current states and generate object to request the api
   * @todo Negligences has it's own implementation
   * @return {object}
   */
  function getQueryData() {
    const activeColumns = vm.columnDef
      .filter(column => !column.hidden && column.hasOwnProperty('fieldName'))
      .map(aC => aC.fieldName);

    // If the sortedColumn is not in columns_to_show, we don't sort the query
    if (vm.sortedColumn && !activeColumns.includes(vm.sortedColumn.fieldName)) {
      vm.sortedColumn = {};
    }

    return {
      query: vm.filterText,
      sort: vm.sortedColumn,
      filter: getFilters(),
      columns: [activeColumns],
      segment: vm.segmentToFilter || ''
    };
  }

  /**
   * Process currentFilters and return a object to send to the api
   * @return {} [description]
   */
  function getFilters() {
    const fieldInfo = vm.filterFieldInfo || {};

    return Object.keys(vm.currentFilters).reduce((memo, filter) => {
      let item = vm.currentFilters[filter];
      let formatValue = item.value;

      if (item && fieldInfo[item.name] && fieldInfo[item.name].type == 'date') {
        if (isNaN(Number(item.value))) {
          formatValue = moment(item.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
        } else {
          formatValue = moment.unix(item.value).format('DD/MM/YYYY');
        }
      }

      memo[item.name] = Object.assign(fieldInfo[item.name], { value: formatValue });

      return memo;
    }, {});
  }

  /**
   * Request data
   * @return {void}
   */
  function requestPage() {
    if (!vm.itemsPath) return;

    vm.prepareFilters();
    const url = `${serverAddress.getBaseUrl()}${vm.itemsPath}${vm.pageSize}/${
      vm.currentPage
    }/json/`;

    vm.busy = true;

    $http
      .get(url, { params: getQueryData() })
      .then(response => {
        $localStorage.set('page-size', vm.pageSize);

        vm.items = response.data.objects;
        vm.meta = response.data.meta;

        vm.nPages = Math.ceil(vm.meta.total_count / vm.pageSize);

        $timeout(() => {
          vm.updateHiddenColumns();
        }, 0);
      })
      .catch(response => {
        const msj = response.data
          ? response.data
          : 'Error desconocido obteniendo el listado de reclamaciones.';
        notifications.addCurrentView('error', msj);
        vm.items = {};
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  /**
   * Update CurrentFilters (real) with newFiltersdata (modal)
   * @return void
   */
  function prepareFilters() {
    var newFilters = vm.newFilters;
    var currentFilters = vm.currentFilters;

    Object.keys(newFilters).forEach(filterName => {
      if (!newFilters.hasOwnProperty(filterName)) return;

      // Some of the items in newFilters can be set to undefined or null. Skip them
      if (!newFilters[filterName]) {
        delete currentFilters[filterName];
        return;
      }

      // Add the new filter to the list of current filters
      currentFilters[filterName] = {
        name: filterName,
        value: newFilters[filterName]
      };

      // get config from initial definition
      const filterConfig = vm.filterFieldInfo[filterName];
      if (!filterConfig) return;

      // Get real value for selects
      if (filterConfig.select_id) {
        currentFilters[filterName].fixed_value = $window
          .$('#' + filterConfig.select_id + ' option:selected')
          .text();
      }

      // Fix Objects => get use_field
      if (filterConfig.json_value) {
        var useField = filterConfig.use_field || 'id';

        currentFilters[filterName].value = currentFilters[filterName].value[useField];
      }
    });
  }

  /**
   * Set scope based on URL => initiate page
   * @return {void}
   */
  function parseUrlParameters() {
    // nothing to do
    if (!$routeParams['filter']) return;

    const params = decodeURIComponent($routeParams['filter']).split('&');

    params.forEach(filter => {
      let [field, value] = filter.split('=');
      if (value === 'undefined') return;

      /**
       * Preprocess some fields for legalfiles only
       */
      if (vm.VALID_NEW_FILTERS) {
        if (vm.VALID_NEW_FILTERS.includes(field)) {
          if (vm.filterFieldInfo[field].type == 'date') {
            vm.newFilters[field] = moment.unix(value).format('DD/MM/YYYY');
          } else {
            vm.newFilters[field] = value;
          }
        }

        if (field === 'legalfile_groups') {
          $scope.newFilters['legalfile_groups'] = { name: value };
        }
      }

      if (!field || !value) return;

      /**
       * Fix knwon filter types and process the rest
       */
      switch (field) {
        case 'segmentToFilter':
          vm.segmentToFilter = value === 'ALL' ? null : value;
          break;

        case 'filterText': // seatch
          vm.filterText = value;
          break;

        case 'sort': // set active sort column
          var sortColumn = value;
          var sortAsc = true;

          if (sortColumn.slice(-2) == '--') {
            // indicamos ordenación inversa así
            sortColumn = sortColumn.substr(0, sortColumn.length - 2);
            sortAsc = false;
          }

          vm.sortedColumn = {
            fieldName: sortColumn,
            order: sortAsc
          };

          const sortString = sortAsc ? 'desc' : 'asc';

          vm.columnDef = vm.columnDef.map(e => {
            e.sorted = e.fieldName == sortColumn ? sortString : null;
            return e;
          });

          break;

        default:
          if (!vm.filterFieldInfo[field]) break; // ignore undefined filters

          /**
           * fix boolean string
           */
          value = ['true', 'false'].includes(value) ? value === 'true' : value;

          /**
           * Fix date values
           */
          const filterIsDate = vm.filterFieldInfo[field].type === 'date';

          const newFilterFixed = filterIsDate ? moment(value * 1000).format('DD/MM/YYYY') : value;
          const currentFilterFixed = {
            name: field,
            value: newFilterFixed
          };

          /**
           * Set the value for the filter modal
           */
          if (vm.filterFieldInfo[field].use_field) {
            var resp = {};
            resp[vm.filterFieldInfo[field].use_field] = newFilterFixed;
            vm.newFilters[field] = resp;
          } else {
            vm.newFilters[field] = newFilterFixed;
          }

          /**
           * Set the value for the tags modal
           */
          vm.currentFilters[field] = currentFilterFixed;
          break;
      }
    });
  }

  /**
   * Process active filters, generate the url for the active search
   * Always redirect or update page
   * @return {undefined}
   */
  function updateItems() {
    /**
     * Search parameters array
     * @type {Array}
     */
    vm.prepareFilters();
    const queryParams = vm.getSearchQueryParams();

    /**
     * Empty state: Go to listPathNoFIlter url or refresh if we're already there
     */
    if (queryParams.length == 0) {
      if ($location.$$path === `/${vm.listPathNoFIlter}`) {
        vm.requestPage();
      } else {
        $location.path('/' + vm.listPathNoFIlter);
      }
      return;
    }

    /**
     * Get url search parameters for the current state
     */
    const searchParams = encodeURIComponent(queryParams.map(e => `${e[0]}=${e[1]}`).join('&'));
    // got to target Url if we're not already there
    if ($location.$$path == '/' + vm.listPath + searchParams) {
      // la url no cambia (cabeceras, nº pagina...) => forzamos el update
      vm.requestPage();
      return;
    }

    // we're already at the page: refresh
    $location.path('/' + vm.listPath + searchParams);
  }

  function onKeyPress(keyEvent) {
    if (keyEvent.which === 13) {
      vm.updateItems();
    }
  }
  /**
   * Process state and generate an array of url Query parameters (filter=valur&....)
   * @return [[type, value], [type, value]....] Array of type value filters arrays
   */
  function getSearchQueryParams() {
    let params = [];

    Object.keys(vm.currentFilters).forEach(value => {
      const currentItem = vm.currentFilters[value];
      if (!vm.filterFieldInfo[currentItem.name])
        throw new Error(`Unknwown filter ${currentItem.name}`);

      if (
        vm.filterFieldInfo[currentItem.name].type == 'date' &&
        typeof currentItem.value == 'string'
      ) {
        params.push([currentItem.name, moment(currentItem.value, 'DD/MM/YYYY').unix()]);
      } else {
        params.push([currentItem.name, currentItem.value]);
      }
    });

    /**
     * Column sorting
     */
    if (vm.sortedColumn && vm.sortedColumn.fieldName) {
      params.push(['sort', vm.sortedColumn.fieldName + (vm.sortedColumn.order ? '' : '--')]);
    }

    /**
     * Parse active search
     */
    if (vm.filterText) params.push(['filterText', vm.filterText]);

    /**
     * segmented filter (only in cases)
     */
    if (vm.segmentToFilter && vm.segmentToFilter !== 'ALL') {
      params.push(['segmentToFilter', vm.segmentToFilter]);
    }

    return params;
  }

  function exportCSV() {
    const filters = getFilters();
    const exportCSVParams = {
      query: vm.filterText,
      sort: vm.sortedColumn,
      filter: filters,
      segment: vm.segmentToFilter || ''
    };
    const url = `${serverAddress.getBaseUrl() + vm.itemsPath + vm.pageSize}/${vm.currentPage}`;

    vm.isExportingCSV = true;

    DownloadCSVService.fetch(url, exportCSVParams);

    alert('La exportación del archivo CSV está en progreso. Por favor, espera un momento...');
    $timeout(function() {
      vm.isExportingCSV = false;
    }, 5000);
  }

  /**
   * Helper method to sort list of objects by key 'name'
   * @param  {object} e
   * @return {string}
   */
  function sortByName(e) {
    return e.name;
  }

  /**
   * Process active Filters display
   * @todo refactor
   * @param  filter The filter to process from vm.currentFilters
   * @return {string} The text displayed in the filter bage
   */
  function fixFilterDisplay(filter) {
    /**
     * Fix some string replacements
     */
    const defaults = {
      true: 'Si',
      false: 'No',
      True: 'Si',
      False: 'No',
      null: '--'
    };

    // Use data set in prepareFilters
    if (filter.fixed_value) {
      return filter.fixed_value;
    }

    // Standar values
    if (defaults[filter.value]) {
      return defaults[filter.value];
    }

    return filter.value;
  }

  function setRequiredPermissions(requiredPermissions) {
    vm.requiredPermissions = requiredPermissions;
  }
}
