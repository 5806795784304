/**
 * WIP: Esta documentación está en proceso y puede cometer errores
 *
 * Métodos y Descripciones:
 *
 * - {@link prepareClaimData}
 *   Realiza ajustes previos a los datos de la reclamación, por ejemplo, convirtiendo
 *   ciertas propiedades de texto a números para facilitar futuros cálculos.
 *
 * - {@link preSaveClaimCB}
 *   Se encarga de preparar la data antes de guardar la reclamación. Verifica si existe
 *   un tipo de producto para almacenar datos específicos o bien ajusta campos numéricos
 *   para evitar envíos de información inservible al servidor.
 *
 * - {@link generateObligationToMakeLawsuit}
 *   Envía una petición para generar el modelo de demanda bancaria. Notifica al usuario
 *   que recibirá un email cuando esté lista.
 *
 * - {@link sendFormalizationExpensesMail}
 *   Lanza una petición para enviarle al cliente un correo con la oferta de gastos de
 *   formalización. Indica el éxito o el fallo al usuario.
 *
 * - {@link showDocumentBuilder}
 *   Abre una ventana modal para combinar documentos seleccionados (merge) según el
 *   tipo de reclamación. Cuando termina, notifica al usuario que recibirá el documento
 *   resultante por email.
 *
 * - {@link showDocumentSlicingModal}
 *   Muestra un modal que permite separar un documento en partes (slicing). Cuando se finaliza,
 *   informa al usuario que la tarea está en proceso y que recibirá la notificación por correo.
 *
 * - {@link showDocumentCopyModal}
 *   Despliega un modal para copiar documentos a otra reclamación o archivo legal. Verifica
 *   que existan documentos y, tras el copiado, actualiza la reclamación para reflejar los cambios.
 *
 * - {@link addCommentSucessCb}
 *   Callback para cuando se añade un comentario exitosamente. Notifica al usuario y refresca
 *   el historial de cambios.
 *
 * - {@link parseCurrenciesAsNumber}
 *   Convierte propiedades numéricas representadas como cadenas de texto en números reales.
 *   Permite al servidor recibir datos apropiados para cálculos posteriores.
 *
 * - {@link downloadAllObligationToMakeDocuments}
 *   Utiliza la factoría de descargas de documentos para bajar todos los ficheros asociados
 *   a la reclamación bancaria.
 *
 * - {@link getPaymentTypeLiteral}
 *   Devuelve la etiqueta en texto (pago, cobro, cobro de costas, etc.) según el tipo de
 *   pago configurado en la reclamación.
 *
 * - {@link showNewCaseModal}
 *   Muestra un modal para generar un nuevo caso a partir de la documentación actual,
 *   validando previamente si se han cargado los documentos en el controlador y, si no,
 *   forzando su recarga antes de lanzar el modal.
 */

import moment from 'moment';

ObligationToMakeClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$http',
  'notifications',
  'serverAddress',
  'DialogFactory',
  'fileUploaderGenerator',
  'ObligationToMakeConstants',
  'DocumentDownloadFactory',
  'DocumentType',
  'ProductService'
];

export default function ObligationToMakeClaimDetailCtrl(
  $scope,
  $controller,
  $http,
  notifications,
  serverAddress,
  DialogFactory,
  fileUploaderGenerator,
  ObligationToMakeConstants,
  DocumentDownloadFactory,
  DocumentType,
  ProductService
) {
  const vm = this;

  const METHODS = {
      prepareClaimData,
      postRefreshClaimCB,
      preSaveClaimCB,

      generateObligationToMakeLawsuit,

      sendFormalizationExpensesMail,

      showDocumentBuilder,
      showDocumentSlicingModal,
      showDocumentCopyModal,
      addCommentSucessCb,
      getDocumentTypes,

      parseCurrenciesAsNumber,
      downloadAllObligationToMakeDocuments,
      getPaymentTypeLiteral
    },
    INITIAL_STATE = {
      claim: {
        client: {
          user: {
            is_active: true
          }
        }
      },
      viewCancelledClientPermission: true,

      // TODO: This variable was used in management.html but never defined. We use now to avoid changing the claim state
      // if we didn't choose client_attendance in the "Juicio señalado" case.
      changeButtonDisabled: false,
      claimType: {},
      productDetails: [],

      // Revolving
      // TODO: Dudoso de borrar, se usa en revolving.html
      r_financial_entity_types: { url: '', values: [] },
      actions: {
        showNewCaseModal
      }
    };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE, METHODS, ObligationToMakeConstants);

  vm.claimDetail.init();

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'obligation-to-make/upload/',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          claim_id: vm.claim_id
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  function getDocumentTypes(cb) {
    vm.documentTypes = vm.claim.type.allowed_document_types;
    vm.clientDocumentTypes = vm.claim.type.allowed_document_types.filter(e => e.client_doc);

    // doh uses the extra 'aircase' type documents
    DocumentType.get({ type: 'aircase' }, response => {
      vm.fileDocumentTypes = response.objects;
    });

    // post-process loaded document types
    if (cb) return cb(vm.documentTypes, vm.clientDocumentTypes);
  }

  function postRefreshClaimCB() {
    vm.getDocumentTypes();
  }

  function preSaveClaimCB() {
    if (vm.claimType.key) {
      const data = Object.assign({ claim: vm.claim }, vm[vm.claimType.key]);
      // Plain child objects references
      Object.keys(data).forEach(currentItem => {
        data[currentItem] = data[currentItem] === undefined ? null : data[currentItem];
        if (data[currentItem] instanceof moment) {
          data[currentItem] = data[currentItem].toISOString(true);
        }
      });
      ProductService.save(vm.claimType.id, data)
        .then(() => {
          notifications.addCurrentView('success', 'Datos guardados correctamente.');
        })
        .catch(error => {
          notifications.addNextView('error', error.data);
        });
    } else {
      /*
      Edge case: If user removes automatic values generated from server on compensation expenses calculator,
      we ensure that it's sending null on this fields: vm.claim.interests, vm.claim.compensation, vm.claim.interests_amortization
      */
      vm.claim.compensation = Number(vm.claim.compensation) || null;
      vm.claim.interests = Number(vm.claim.interests) || null;
      vm.claim.interests_amortization = Number(vm.claim.interests_amortization) || null;
    }
  }

  // process changes in claim before initialized
  // TODO: Esto es adhoc para banca y debe ser refactorizado
  function prepareClaimData(claimData) {
    // TODO: Remove when back returns currencies as number
    vm.parseCurrenciesAsNumber(claimData, [
      'compensation',
      'invoiced_amount',
      'compensation_total',
      'estimated_debt_reduction',
      'compensation_with_sustitutive_reference_type',
      'estimated_debt_reduction_with_sustitutive_reference_type',
      'interests',
      'shares_invested_amount',
      'shares_exchange_value',
      'shares_current_value',
      'loan_amount',
      'irph_mortgage_fixed_sustitutive_reference_type',
      'irph_mortgage_fixed_sustitutive_reference_type',
      'irph_mortgage_mortgage_constitution_expenses',
      'ceil_clause_percentage',
      'floor_clause_fixed_interest_rate',
      'floor_clause_differential',
      'floor_clause_percentage'
      // claim.claim.amortizations[].amount
      // claim.increments[].amount
    ]);

    return claimData;
  }

  function parseCurrenciesAsNumber(claimData, properties = []) {
    properties.forEach(p => (claimData[p] = Number(claimData[p]) || null));
  }

  function showDocumentSlicingModal() {
    const claimData = {
      claim_id: vm.claim_id,
      claim_prefix: 'OH'
    };
    DialogFactory.sliceDocument(vm.documents, claimData, function() {
      var successMsg = 'Separación de documento solicitada. Cuando termine recibirás un email.';
      vm.claimDetail.update(successMsg);
    });
  }

  function showDocumentBuilder() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimTypeId = vm.claim.type.id;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: 'OH',
      claim_type: claimTypeId,
      claim_segment: 'obligationtomake'
    };

    var multipleSourceSelectionEnabled = [39, 56, 6, 30].includes(claimTypeId);

    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function() {
        var successMsg = 'Generación de documento solicitada. Cuando termine recibirás un email.';
        vm.claimDetail.update(successMsg);
      }
    );
  }
  // TODO: This method could me moved to base controller
  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var claimTypes = vm.claimTypes;
    var multipleSourceSelectionEnabled = false;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: prefix
    };
    var copy_type = type;

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ' + 'ningún documento adjunto.'
      );
      return;
    }

    let method = '';

    switch (type) {
      case 'claim':
        method = 'copyDocsToClaimValidation';
        break;
      case 'legalfile':
        method = 'copyDocsToLegalfileValidation';
        break;
      case 'obligation_to_make_claim':
        method = 'copyOnlyValidatedDocumentsValidation';
        claimData['targetClaim'] = vm.claim.obligation_to_make_claim.full_id;
        break;
      case 'original_claim':
        method = 'copyOnlyValidatedDocumentsValidation';
        claimData['targetClaim'] = vm.claim.original_claim.full_id;
        break;
      default:
        break;
    }

    try {
      currentDocuments = DialogFactory[method](vm.documents, vm.claim);
    } catch (e) {
      notifications.addCurrentView('error', e);
      return;
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function () {
        vm.claimDetail.update();
      }
    );
  }

  function generateObligationToMakeLawsuit() {
    notifications.addCurrentView(
      'info',
      'Generando modelo de demanda. Por favor, espera un momento. Ya casi lo tenemos...'
    );

    $http({
      url: serverAddress.getBaseUrl() + '/legalfile/OH/generate_obligationtomake_lawsuit/',
      method: 'POST',
      data: {
        claim_id: vm.claim_id,
        segment: 'obligation_to_make',
        legalfile_doc: true,
        new_draft: 1
      }
    })
      .then(function() {
        vm.claimDetail.update(
          'Operación lanzada, recibirás un email cuando el ' + 'documento esté listo :-)'
        );
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  // TODO: Borrar si no se usa la tab Mismo cliente
  function sendFormalizationExpensesMail() {
    notifications.addCurrentView('info', 'Enviando email al cliente');
    $http
      .post(serverAddress.getBaseUrl() + 'banco/claim/formalizationexpenses-offer/', {
        claim_id: vm.claim_id
      })
      .then(function() {
        notifications.clear();
        notifications.addCurrentView(
          'success',
          'Se ha enviado el email al cliente correctamente: '
        );
      })
      .catch(function(response) {
        notifications.clear();
        notifications.addCurrentView('error', response.data);
      });
  }

  function addCommentSucessCb() {
    vm.claimDetail.update('Comentario añadido con éxito').then(function() {
      vm.fetchHistoryChanges(true);
    });
  }

  function downloadAllObligationToMakeDocuments() {
    DocumentDownloadFactory.downloadAllObligationToMakeDocuments(vm.claim);
  }

  function getPaymentTypeLiteral() { // TODO: Verificar que hacer con esta función tan adhoc
    let v = '';
    switch (vm.claim.payment_type_id) {
      case 1:
        v = 'pago';
        break;
      case 2:
      case 3:
        v = 'cobro';
        break;
      case 4:
        v = 'cobro de costas';
        break;
      default:
        break;
    }
    return v;
  }

  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView('error', `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`);
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
