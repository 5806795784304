export default [
  {
    title: 'Inicio',
    url: '#/',
    icnClass: 'fa-home'
  },
  {
    title: 'Clientes',
    url: '#/clients',
    permission: 'client-global-crm_view_client_list',
    icnClass: 'fa-user'
  },
  {
    title: 'Gestiones',
    url: null,
    permission: 'segment-perobject-GE-crm_view_segment_section',
    icnClass: 'fa-id-card',
    elms: [
      {
        title: 'Listado de gestiones',
        url: '#/managements-claim',
        permission: 'segment-perobject-GE-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Aerolineas',
    url: null,
    permission: 'segment-perobject-AE-crm_view_segment_section',
    icnClass: 'fa-plane',
    elms: [
      {
        title: 'Panel abogados',
        url: '#/airlines/lawyers/counts',
        permission: 'segment-perobject-AE-crm_view_lawyers_dashboard'
      },
      {
        title: 'Panel comerciales',
        url: '#/airlines/salespeople/counts',
        permission: 'segment-perobject-AE-crm_view_salespeople_dashboard'
      },
      {
        title: 'Objetivos abogados',
        url: '#/airlines/lawyers/goals',
        permission: 'segment-perobject-AE-crm_view_lawyers_goals_dashboard'
      },
      {
        title: 'Objetivos comerciales',
        url: '#/airlines/salespeople/goals',
        permission: 'segment-perobject-AE-crm_view_salespeople_goals_dashboard'
      },
      {
        title: 'Reclamaciones pendientes validación',
        url: '#/airlines/pending-validation',
        permission: 'segment-perobject-AE-crm_view_pending_validation_panel'
      },
      {
        title: 'Reclamaciones',
        url: '#/airline-claims',
        permission: 'segment-perobject-AE-crm_view_claims_list'
      },
      {
        title: 'Vuelos',
        url: '#/airlines/flights',
        permission: 'segment-global-crm_view_flights_list'
      }
    ]
  },
  {
    title: 'Banca',
    url: null,
    permission: 'segment-perobject-BA-crm_view_segment_section',
    icnClass: 'fa-briefcase',
    elms: [
      {
        title: 'Panel abogados',
        url: '#/bank/lawyers/counts',
        permission: 'segment-perobject-BA-crm_view_lawyers_dashboard'
      },
      {
        title: 'Panel comerciales',
        url: '#/bank/salespeople/counts',
        permission: 'segment-perobject-BA-crm_view_salespeople_dashboard'
      },
      {
        title: 'Reclamaciones',
        url: '#/bank-claims',
        permission: 'segment-perobject-BA-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Impuestos',
    url: null,
    permission: 'segment-perobject-TA-crm_view_segment_section',
    icnClass: 'far fa-money-bill-alt',
    elms: [
      {
        title: 'Panel abogados',
        url: '#/taxes/lawyers/counts',
        permission: 'segment-perobject-TA-crm_view_lawyers_dashboard'
      },
      {
        title: 'Panel comerciales',
        url: '#/taxes/salespeople/counts',
        permission: 'segment-perobject-TA-crm_view_salespeople_dashboard'
      },
      {
        title: 'Reclamaciones',
        url: '#/taxes-claims',
        permission: 'segment-perobject-TA-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Laboral',
    url: null,
    permission: 'segment-perobject-LA-crm_view_segment_section',
    icnClass: 'fa-users',
    elms: [
      {
        title: 'Panel abogados',
        url: '#/laboral/lawyers/counts',
        permission: 'segment-perobject-LA-crm_view_lawyers_dashboard'
      },
      {
        title: 'Panel comerciales',
        url: '#/laboral/salespeople/counts',
        permission: 'segment-perobject-LA-crm_view_salespeople_dashboard'
      },
      {
        title: 'Reclamaciones',
        url: '#/laboral-claims',
        permission: 'segment-perobject-LA-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Accidentes',
    url: null,
    permission: 'segment-perobject-AC-crm_view_segment_section',
    icnClass: 'fa-road',
    elms: [
      {
        title: 'Panel abogados',
        url: '#/accidents/lawyers/counts',
        permission: 'segment-perobject-AC-crm_view_lawyers_dashboard'
      },
      {
        title: 'Panel comerciales',
        url: '#/accidents/salespeople/counts',
        permission: 'segment-perobject-AC-crm_view_salespeople_dashboard'
      },
      {
        title: 'Reclamaciones',
        url: '#/accident-claims',
        permission: 'segment-perobject-AC-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Negligencias',
    url: null,
    permission: 'segment-perobject-NE-crm_view_segment_section',
    icnClass: 'fa-ambulance',
    elms: [
      {
        title: 'Panel abogados',
        url: '#/negligences/lawyers/counts',
        permission: 'segment-perobject-NE-crm_view_lawyers_dashboard'
      },
      {
        title: 'Panel comerciales',
        url: '#/negligences/salespeople/counts',
        permission: 'segment-perobject-NE-crm_view_salespeople_dashboard'
      },
      {
        title: 'Reclamaciones',
        url: '#/negligences-claims',
        permission: 'segment-perobject-NE-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Servicio Genérico',
    url: null,
    permission: 'segment-perobject-SG-crm_view_segment_section',
    icnClass: 'fa-id-card',
    elms: [
      {
        title: 'Listado de gestiones',
        url: '#/legalservices-claims',
        permission: 'segment-perobject-SG-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Segunda oportunidad',
    url: null,
    permission: 'segment-perobject-SO-crm_view_segment_section',
    icnClass: 'far fa-thumbs-up',
    elms: [
      {
        title: 'Listado de segunda oportunidad',
        url: '#/so-claims',
        permission: 'segment-perobject-SO-crm_view_claims_list'
      }
    ]
  },
  {
    title: 'Otras reclamaciones',
    url: null,
    icnClass: 'fa-comment-alt',
    elms: [
      {
        title: 'Genéricas',
        url: '#/generic-claims',
        permission: 'segment-global-crm_view_generic_claims_section',
        icnClass: 'fa-comment-alt'
      },
      {
        title: 'Telefonía',
        url: '#/phone-claims',
        icnClass: 'fa-phone-volume',
        permission: 'segment-perobject-TE-crm_view_claims_list'
      },
      {
        title: 'Extranjería (old)',
        url: '#/foreignerservices',
        permission: 'segment-perobject-EX-crm_view_costs_dashboard'
      }
    ]
  },
  {
    title: 'Procesal',
    url: null,
    permission: 'segment-global-crm_view_legalfile_list',
    icnClass: 'fa-gavel',
    elms: [
      {
        title: 'Expedientes procesales',
        url: '#/legalfiles',
        permission: 'segment-global-crm_view_legalfile_list'
      },
      {
        title: 'Control procesal',
        url: '#/legalfiles/procedural-control',
        permission: 'segment-global-crm_view_procedural_control_panel'
      },
      {
        title: 'Panel de gestión',
        url: '#/legalfiles/management-notifications',
        permission: 'segment-global-crm_notification_assignable_lawyer'
      }
    ]
  },  
  {
    title: 'Costas',
    url: '#/costs',
    permission: 'segment-global-crm_view_costs_dashboard',
    icnClass: 'fa-tag'
  },

  {
    title: 'DOH',
    url: null,
    permission: 'segment-perobject-OH-crm_view_segment_section',
    icnClass: 'fa-file-contract',
    elms: [
      {
        title: 'Panel abogados',
        url: '#/obligationtomake/lawyers/counts',
        permission: 'segment-perobject-OH-crm_view_lawyers_dashboard'
      },
      {
        title: 'Reclamaciones',
        url: '#/obligationtomake-claims',
        permission: 'segment-perobject-OH-crm_view_claims_list'
      }
    ]
  },

  {
    title: 'Cambios masivos',
    url: null,
    icnClass: 'fa-file',
    permission: [
      'segment-global-crm_execute_bulk_changes',
      'segment-global-crm_manage_bulk_documentation'
    ],
    elms: [
      {
        title: 'Cambios masivos',
        url: '#/massive-changes/',
        permission: 'segment-global-crm_execute_bulk_changes'
      },
      {
        title: 'Gestión masiva de documentos',
        url: '#/documents-inbox/',
        permission: 'segment-global-crm_manage_bulk_documentation'
      },
      {
        title: 'Descarga masiva de documentos',
        url: '#/massive-doc-download/',
        permission: 'segment-global-crm_execute_bulk_changes'
      },
      {
        title: 'Compra de cartera',
        url: '#/bought-portfolio/',
        permission: 'segment-global-crm_execute_bulk_changes'
      }
    ]
  },

  {
    title: 'Analítica',
    url: null,
    icnClass: 'fa-chart-line',
    elms: [
      {
        title: 'Envío de emails',
        url: '#/emails/',
        permission: 'segment-global-crm_view_analytics_section'
      },
      {
        title: 'Dashboard Analítica',
        url: '#/dashboard/operations/',
        permission: 'segment-global-crm_view_dashboards_section',
        icnClass: 'fa-tachometer-alt',
        elms: []
      }
    ]
  },
  {
    title: 'Configurar',
    url: process.env.ADMIN_URL,
    ext: true,
    permission: 'segment-global-crm_view_admin_section',
    icnClass: 'fa-wrench'
  }
];
