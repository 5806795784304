export default function constantsFactory() {
  return {
    getDateOptions,
    getSegmentAlias
  };

  function getDateOptions() {
    return {
      dateFormat: 'dd/mm/yy',
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      today: 'Hoy',
      firstDay: 1,
      suffix: [],
      meridiem: []
    };
  }

  function getSegmentAlias(prefix) {
    const dic = {
      BA: { alias: 'bank', title: 'Banca' },
      OH: { alias: 'obligationtomake', title: 'Demandas de Obligación de Hacer' },
      AE: { alias: 'airline', title: 'Aerolíneas' },
      AC: { alias: 'accident', title: 'Accidentes' },
      NE: { alias: 'negligences', title: 'Negligencias' },
      PO: { alias: 'phone', title: 'Telefonía' },
      LA: { alias: 'laboral', title: 'Laboral' },
      TA: { alias: 'taxes', title: 'Impuestos' },
      SO: { alias: 'so', title: 'Segunda Opportunidad' },
      GE: { alias: 'managements', title: 'Gestiones' },
      SG: { alias: 'legalservices', title: 'Servicio genérico' },
      EX: { alias: 'foreignerservices', title: 'Extranjería' },
      ALL: { alias: 'all', title: 'Todos' }
    };
    return dic[prefix] || {};
  }
}
