/* overload methods Array.prototype */

Array.prototype.sortBy = function(property) {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return this.sort((a, b) => {
    const result = a[property] < b[property] ? -1 : a[property] > b[property];
    return result * sortOrder;
  });
};

Array.prototype.uniq = () => this.filter((elem, pos, arr) => arr.indexOf(elem) == pos);

Array.prototype.groupBy = function(prop) {
  return this.reduce((groups, item) => {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

/* Other util functions */ 

export function dynamicSort(property) {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return (a, b) => {
    const result = a[property] < b[property] ? -1 : a[property] > b[property];
    return result * sortOrder;
  };
}

export function casting2Number(obj) {
  const objectParsed = {};
  for(var prop in obj){
    if(obj.hasOwnProperty(prop) && obj[prop] !== null && obj[prop] !== '' && !isNaN(obj[prop])){
      objectParsed[prop] = +obj[prop];   
    } else {
      objectParsed[prop] = null;   
    }
  }
  return objectParsed;
}

export async function fetchAsync(url) {
  const response = await fetch(url);
  return await response.json();
}

export function getSegmentName(prefix) {
  const segmentPaths = {
    AC: 'accident',
    AE: 'airline',
    BA: 'bank',
    LA: 'laboral',
    NE: 'negligences',
    TA: 'taxes',
    TE: 'phone',
    SO: 'so',
    GE: 'managements',
    SG: 'legalservices',
    OH: 'obligationtomake'
  };
  return segmentPaths[prefix] ? segmentPaths[prefix] : '';
}
export function getSegmentPath(prefix) {
  const segmentPaths = {
    AC: 'accident-claims',
    AE: 'airline-claims',
    BA: 'bank-claims',
    LA: 'laboral-claims',
    NE: 'negligences-claims',
    TA: 'taxes-claims',
    TE: 'phone-claims',
    SO: 'so-claims',
    GE: 'managements',
    SG: 'legalservices',
    OH: 'obligationtomake-claims'
  };
  return segmentPaths[prefix] ? segmentPaths[prefix] : '';
}

export function removeSpacesPhone(phoneNumber) {
  return phoneNumber ? phoneNumber.replace(/[\s,.-]/g, '') : phoneNumber;
}
