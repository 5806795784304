'use strict';

import angular from 'angular';

let env = angular.module("reclamador.version", [])

.constant("version", "114.0.2")

;

export default { env, version: "114.0.2" };
